import Explorer from "../components/Crm/treeFolder/clientfiles/Explorer";
import ExplorerParticipantsCategories from "../components/Crm/treeFolder/interlocutors/ExplorerParticipantsCategories";
import Tabs from "../components/Crm/buttons/Tabs";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import useModal from "../hooks/useModal";
import { useExplorer } from "../store/Crm/Explorer";
import { useUtilsCrmStore } from "../store/Crm/utilsCrm";
import Analytic from "../components/Crm/modales/Analytic";
import { useEffect } from "react";
import DocumentsList from "../components/Crm/modales/DocumentsList";
import Mission from "../components/Crm/modales/Mission";
import CreateClientFileExpertComptable from "../components/Crm/modales/EcClientFile/CreateClientFileExpertComptable";
import UpdateClientFile from "../components/Crm/modales/EcClientFile/UpdateClientFile";
import { useClientFileStore } from "../store/Crm/ClientFile";
import UpdateInterlocuteurEC from "../components/Crm/modales/IntervenantEc/UpdateInterlocuteurEc";
import { useIntervenantStore } from "../store/Crm/Intervenant";
import CreateIntervenantEc from "../components/Crm/modales/IntervenantEc/CreateIntervenantEc";

/**
 * @page
 * @name Crm
 * @description
 * CRM page with the explorer, the tabs, the modal and the client file
 * @author Iris Succi
 * @date 2023
 */
const Crm = () => {
  //Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  // Store
  const { choiceCreate, setChoiceCreate } = useIntervenantStore();
  const { ficRef, setFicRef, PDOS_ID, PENT_ID, ficRefIntervenant } =
    useExplorer();
  const { choiceTab } = useUtilsCrmStore();
  const { setData } = useClientFileStore();

  useEffect(() => {
    if (choiceTab === "Create") {
      setFicRef(0);
      setData({});
    }
  }, [choiceTab]);

  useEffect(() => {
    if (choiceTab === "CreateIntervenant") {
      setData({});
    }
  }, [choiceCreate]);

  useEffect(() => {
    if (ficRefIntervenant === 0 && choiceTab === "Intervenant") {
      setChoiceCreate("");
    }
  }, [PENT_ID, ficRefIntervenant]);

  return (
    <div className="w-screen h-screen overflow-hidden">
      {isModalOpen ? modalContent : null}
      <Header closeModal={closeModal} openModal={openModal} />
      <div className="flex w-screen">
        <div className="h-auto">
          <Navbar />
        </div>
        <div className="flex w-full ml-10 h-[87vh] justify-start items-center">
          <div className="w-1/5 h-[98%] mt-8">
            {/* Explorer choice with choiceTab */}
            {choiceTab === "Create" ||
            choiceTab === "Fiche client" ||
            choiceTab === "Analytique" ||
            choiceTab === "Documents" ||
            choiceTab === "Missions" ||
            choiceTab === "" ? (
              <Explorer />
            ) : null}
            {choiceTab === "Intervenant" && PDOS_ID !== 0 ? (
              <ExplorerParticipantsCategories />
            ) : null}
          </div>

          <div className="w-4/5 h-[95%]">
            {/* Modal choice with choiceTab  */}
            {ficRef === 0 && choiceTab === "" ? (
              <p className="text-center mt-4">
                Vous devez selectionner un dossier client
              </p>
            ) : choiceTab === "Create" ? (
              <CreateClientFileExpertComptable />
            ) : (
              <Tabs />
            )}
            {choiceTab === "Fiche client" && ficRef !== 0 ? (
              <UpdateClientFile />
            ) : choiceTab === "Analytique" && ficRef !== 0 ? (
              <Analytic />
            ) : choiceTab === "Documents" && ficRef !== 0 ? (
              <DocumentsList />
            ) : choiceTab === "Missions" && ficRef !== 0 ? (
              <Mission />
            ) : choiceTab === "Intervenant" &&
              choiceCreate === "UpdateIntervenant" &&
              ficRefIntervenant !== 0 ? (
              <UpdateInterlocuteurEC />
            ) : choiceTab === "Intervenant" &&
              choiceCreate === "CreateIntervenant" ? (
              <CreateIntervenantEc />
            ) : choiceTab === "Intervenant" &&
              choiceCreate === "" &&
              ficRefIntervenant === 0 ? (
              <p className="text-center mt-4">
                Veuillez selectionner un Interlocuteur
              </p>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Crm;
