import TimeManagement from "../pages/TimeManagement";
import { Route, Routes as RouterRoutes, useLocation } from "react-router-dom";
import Connection from "../pages/Connection";
import Home from "../pages/Home";
import Error from "../pages/Error";
import { useRightContext } from "../context/RightContext";
import RedirectToError from "../components/Utils/RedirectToError";
import Invoice from "../pages/Invoice";
import FinancialStatement from "../pages/FinancialStatement";
import Crm from "../pages/Crm";
import { useUserContext } from "../context/UserContext";
import Analyse from "../pages/Analyse";
import Cost from "../pages/Cost";
import { useEffect } from "react";

const Routes = () => {
  //Context
  const { buroFact, buroTime, etats } = useRightContext();
  const { session } = useUserContext();

  // This is for deleting the local storage when the user changes the page
  const location = useLocation();
  const pathTime = location.pathname.includes("/timemanagement");
  const pathCost = location.pathname.includes("/costs");

  useEffect(() => {
    if (!pathTime) localStorage.removeItem("dosRef");
    else if (!pathCost) localStorage.removeItem("dosRefCost");
  }, [location]);

  return (
    <>
      <RouterRoutes>
        <Route path="/login/:alias" element={<Connection />} />
        <Route path="*" element={<RedirectToError />} />
        <Route path="/error" element={<Error />} />
        {session ? (
          <>
            <Route path="/:alias/home" element={<Home />} />
            <Route path="/:alias/invoicing" element={<Invoice />} />
            {buroTime === 1 && (
              <Route
                path="/:alias/timemanagement"
                element={<TimeManagement />}
              />
            )}
            {buroFact === 1 && <Route path="/:alias/crm" element={<Crm />} />}
            {etats === 1 && (
              <Route
                path="/:alias/financialstatement"
                element={<FinancialStatement />}
              />
            )}
            <Route path="/error" element={<Error />} />
            <Route path="/:alias/analyse" element={<Analyse />} />
            <Route path="/:alias/costs" element={<Cost />} />
          </>
        ) : (
          <>
            <Route path={`/login/:alias`} element={<Connection />} />
          </>
        )}
      </RouterRoutes>
    </>
  );
};

export default Routes;
