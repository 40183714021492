import { useCallback, useEffect } from "react";
import { handlePhoneChange } from "../../../../utils/functionService";
import { debounce } from "lodash";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import axios from "axios";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getImplicitGroups } from "../../../../utils/customerFile";

const RenderInterlocuteurEc = () => {
  const {
    fields,
    cp,
    setCp,
    city,
    setCity,
    localCity,
    setLocalCity,
    localCp,
    setLocalCp,
    data,
    setData,
    implicitGroups,
    setImplicitGroups,
    selectedGroup,
    setSelectedGroup,
    date,
  } = useIntervenantStore();
  const { numberDoss } = useExplorer();

  // Fonction pour générer les champs en fonction du type de champ (text, textarea, date, choice-list, phone)
  const inputType = (
    type: string,
    name: string,
    getter: any,
    setter: any,
    options?: any
  ) => {
    switch (type) {
      case "text":
        return (
          <input
            type="text"
            name={name}
            id={name}
            onChange={(e) => setter(e.target.value)}
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      case "textarea":
        return (
          <textarea
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full h-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "date":
        return (
          <input
            type="date"
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          />
        );
      case "choice-list":
        return (
          <select
            name={name}
            id={name}
            className="border-[1px] rounded-md w-full p-2"
            value={getter}
            onChange={(e) => setter(e.target.value)}
          >
            <option value="" className="text-slate-300 "></option>
            {options?.map((option: any, i: any) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        );
      case "phone":
        return (
          <input
            type="tel"
            name={name}
            id={name}
            onChange={(e) => handlePhoneChange(e, setter)} // Appelle la fonction handlePhoneChange pour creer un espace
            value={getter}
            className="border-[1px] rounded-md w-full p-2"
          />
        );
      default:
        return "text";
    }
  };

  // Fonction débouncée pour mettre à jour le code postal
  const debounceSetCp = useCallback(
    debounce((value) => setCp(value), 500),
    []
  );

  // Fonction débouncée pour mettre à jour la ville
  const debounceSetCity = useCallback(
    debounce((value) => setCity(value), 500),
    []
  );

  // Appel API pour récupérer les villes par code postal
  useEffect(() => {
    if (cp === "") {
      setCity("");
      setLocalCity("");
      return;
    }
    axios
      .get(`https://geo.api.gouv.fr/communes?codePostal=${localCp}`)
      .then((res) => {
        if (res.data.length > 0) {
          setCity(res.data[0].nom); // Prend la première ville
          setLocalCity(res.data[0].nom); // Synchronise l'input local
        }
      })
      .catch((err) => console.error(err));
  }, [cp]);

  // Appel API pour récupérer le code postal par ville
  useEffect(() => {
    if (city === "") {
      setCp("");
      setLocalCp("");
      return;
    }
    axios
      .get(`https://geo.api.gouv.fr/communes?nom=${localCity}`)
      .then((res) => {
        if (res.data.length > 1) {
          // Parcourt la liste pour trouver le nom exact, en ignorant la casse
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].nom.toLowerCase() === city.toLowerCase()) {
              // Comparaison insensible à la casse
              setCp(res.data[i].codesPostaux[0]);
              setLocalCp(res.data[i].codesPostaux[0]); // Synchronise l'input local
              return; // Arrête la boucle une fois trouvé
            }
          }
        } else {
          setCp(res.data[0].codesPostaux[0]);
          setLocalCp(res.data[0].codesPostaux[0]); // Synchronise l'input local
        }
      })
      .catch((err) => console.error(err));
  }, [city]);

  useEffect(() => {
    getImplicitGroups()
      .then((response) => {
        const formattedData = response.data.map((group: any) => ({
          value: group.DOS_CODETRAVAIL + " - " + group.DOS_LIBTRAVAIL,
          label: group.DOS_LIBTRAVAIL,
        }));
        setImplicitGroups(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Dossier</p>
      </div>
      <div className="col-start-2 col-end-3" id="numerodossier">
        <input
          type="text"
          id="numerodossier"
          name="numerodossier"
          className="border-[1px] rounded-md w-full p-2"
          value={numberDoss}
          disabled
        />
      </div>

      <div className="col-start-3 col-end-4 flex justify-start items-center">
        <p>Création</p>
      </div>
      <div className="col-start-4 col-end-5" id="creation">
        <input
          type="date"
          id="creation"
          name="creation"
          disabled
          className="border-[1px] rounded-md w-full p-2"
          value={date ? new Date(date).toISOString().split("T")[0] : ""}        
        />
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Opérateur</p>
      </div>
      <div className="col-start-2 col-end-3" id="FIC_OPE">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "FIC_OPE");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Acteur</p>
      </div>
      <div className="col-start-2 col-end-3" id="FIC_REDAC">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "FIC_REDAC");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Groupe</p>
      </div>
      <div className="col-start-2 col-end-3" id="Groupe">
        <select
          name="implicitegroup"
          id="implicitegroup"
          value={selectedGroup}
          className="border-[1px] rounded-md w-full p-2"
          onChange={(e) => {
            setSelectedGroup(e.target.value);
          }}
        >
          <option value="" className="text-slate-300 "></option>
          {implicitGroups?.map((item: any, i: any) => (
            <option key={i} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>

      <hr className="col-start-2 col-end-5 my-2" />

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p className="font-bold">NOM</p>
      </div>
      <div className="col-start-2 col-end-5" id="Nom / Dénomination sociale">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Nom / Dénomination sociale"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Prénom</p>
      </div>
      <div className="col-start-2 col-end-3" id="PP Prénom">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "PP Prénom");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-3 col-end-5 flex justify-start items-center">
        <p className="w-1/3">Préfixe</p>
        <div className="w-2/3" id="PP Préfixe">
          {(() => {
            const obj = fields?.find((item: any) => item.nom === "PP Préfixe");
            return obj
              ? inputType(
                  obj.type,
                  obj.nom,
                  data[obj.nom] || "", // getter avec une valeur par défaut
                  (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                  obj.options // options pour les listes déroulantes
                )
              : null;
          })()}
        </div>
      </div>

      <div className="col-start-3 col-end-4 flex justify-start items-center">
        <p>Appellation courrier</p>
      </div>
      <div className="col-start-4 col-end-5" id="Appellation courrier">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Appellation courrier"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-3 col-end-4 flex justify-start items-center">
        <p>Fonction</p>
      </div>
      <div className="col-start-4 col-end-5" id="Qualité">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Qualité");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-3 col-end-4 flex justify-start items-center">
        <p>Service</p>
      </div>
      <div className="col-start-4 col-end-5" id="Service">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Service");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <hr className="col-start-2 col-end-5 my-2" />

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>Adresse</p>
      </div>
      <div
        className="col-start-2 col-end-4 w-full"
        id="Adresse principale (Rue)"
      >
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Adresse principale (Rue)"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div
        className="col-start-4 col-end-5 flex justify-start items-start"
        id="Téléphone standard"
      >
        <p>Téléphone standard</p>
      </div>
      <div className="col-start-5 col-end-6 flex justify-start items-start">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone standard"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div className="col-start-1 col-end-2 flex justify-start items-center">
        <p>CP/VILLE</p>
      </div>
      <div className="col-start-2 col-end-3 grid grid-cols-3 w-full gap-1">
        <div className="w-full col-start-1 col-end-2 mr-1">
          <input
            type="text"
            name="Adresse principale (CP)"
            value={localCp} // On utilise l'état local
            id="Adresse principale (CP)"
            disabled={false}
            onChange={(e) => {
              const value = e.target.value;
              setLocalCp(value); // Mets à jour l'input immédiatement
              debounceSetCp(value); // Mets à jour avec un délai
            }}
            className="border-[1px] rounded-md w-full p-2 "
          />
        </div>
        <div className="w-full col-start-2 col-end-4">
          <input
            type="text"
            name="Adresse principale (Ville)"
            value={localCity} // On utilise l'état local
            id="Adresse principale (Ville)"
            disabled={false}
            onChange={(e) => {
              const value = e.target.value;
              setLocalCity(value); // Mets à jour l'input immédiatement
              debounceSetCity(value); // Mets à jour avec un délai
            }}
            className="border-[1px] rounded-md w-full p-2"
          />
        </div>
      </div>

      <div
        className="col-start-4 col-end-5 flex justify-start items-start"
        id="Téléphone Portable"
      >
        <p>Portable</p>
      </div>
      <div className="col-start-5 col-end-6 flex justify-start items-start">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone Portable"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div
        className="col-start-4 col-end-5 flex justify-start items-start"
        id="Téléphone personnel"
      >
        <p>Téléphone personnel</p>
      </div>
      <div className="col-start-5 col-end-6 flex justify-start items-start">
        {(() => {
          const obj = fields?.find(
            (item: any) => item.nom === "Téléphone personnel"
          );
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>

      <div
        className="col-start-4 col-end-5 flex justify-start items-start"
        id="Email"
      >
        <p>Email</p>
      </div>
      <div className="col-start-5 col-end-6 flex justify-start items-start">
        {(() => {
          const obj = fields?.find((item: any) => item.nom === "Email");
          return obj
            ? inputType(
                obj.type,
                obj.nom,
                data[obj.nom] || "", // getter avec une valeur par défaut
                (value: any) => setData({ ...data, [obj.nom]: value }), // setter pour mettre à jour l'état
                obj.options // options pour les listes déroulantes
              )
            : null;
        })()}
      </div>
    </div>
  );
};

export default RenderInterlocuteurEc;
