import { useExplorer } from "../../../store/Crm/Explorer";
import { useUtilsCrmStore } from "../../../store/Crm/utilsCrm";
import { useLoadingStore } from "../../../store/utils/loading";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import { getMissionsDetailedByDosRef } from "../../../utils/customerFile";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useEffect } from "react";

const Mission = () => {
  const { isLoading } = useLoadingStore();
  const { missions, setMissions } = useUtilsCrmStore();
  const { dosRef } = useExplorer();

  useEffect(() => {
    getMissionsDetailedByDosRef(dosRef)
      .then((response) => setMissions(response.data))
      .catch((error) => {
        console.error(error);
      });
  }, [dosRef]);

  return (
    <div className="relative border-[1px] rounded-b-md w-[90%] h-full overflow-x-hidden">
      {isLoading ? (
        <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
          <CircleLoarder />
        </div>
      ) : (
        <table className="w-full h-full overflow-x-auto flex flex-col justify-start items-center border-[1px] text-sm overflow-y-scroll">
          <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0 rounded-sm">
            <th className="w-[800px] border-r-[1px] font-normal h-auto">
              <p className="py-2 w-full font-bold">Infos</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Code
                </th>
                <th className="w-[300px] font-normal text-start h-full pl-1">
                  Libelle
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Debut
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Fin
                </th>
                <th className="w-[50px] font-normal text-start h-full pl-1">
                  Chef
                </th>
                <th className="w-[50px] font-normal text-start h-full pl-1">
                  Collab
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Num
                </th>
              </tr>
            </th>
            <th className="w-[300px] border-r-[1px] font-normal text-center bg-[#FFF6A5] h-auto">
              <p className="py-2  font-bold">Temps</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Plan charge
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Tps Passés
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Tps Validés
                </th>
              </tr>
            </th>
            <th className="w-[100px] border-r-[1px] font-normal text-center bg-[#B5DCDE] h-auto">
              <p className="py-2 font-bold">Budget</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[100px] font-normal text-center h-full pl-1">
                  Bud. Hono.
                </th>
              </tr>
            </th>
            <th className="w-[200px] border-r-[1px] font-normal text-center bg-[#8AC874] h-auto">
              <p className="py-2 font-bold">Valorisation</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Tps Passés
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Tps Validés
                </th>
              </tr>
            </th>
            <th className="w-[300px] border-r-[1px] font-normal text-center bg-[#E3B66E] h-auto">
              <p className="py-2 font-bold">Budget & acquis Cab.</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[150px] font-normal text-start h-full pl-1">
                  Budget Cab.
                </th>
                <th className="w-[150px] font-normal text-start h-full pl-1">
                  Acquis. Cab.
                </th>
              </tr>
            </th>
            <th className="w-[300px] border-r-[1px] font-normal text-center bg-[#BEA9E1] h-auto">
              <p className="py-2 font-bold">Acquis</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[150px] font-normal text-start h-full pl-1">
                  Avancement
                </th>
                <th className="w-[150px] font-normal text-start h-full pl-1">
                  Reste à faire
                </th>
              </tr>
            </th>
            <th className="w-[300px] border-r-[1px] font-normal text-center bg-[#FF9E9E] h-auto">
              <p className="py-2 font-bold">Facturation</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  Hono. Fact.
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  DI Fact.
                </th>
                <th className="w-[100px] font-normal text-start h-full pl-1">
                  DE Fact.
                </th>
              </tr>
            </th>
            <th className="w-[300px] border-r-[1px] font-normal text-center h-auto bg-[#cecece]">
              <p className="py-2 font-bold">Abonnement</p>
              <tr className="flex w-full font-thin border-t-[1px] h-auto py-1">
                <th className="w-[300px] font-normal text-start h-full pl-1">
                  En cours
                </th>
              </tr>
            </th>
          </thead>
          <tbody className="flex flex-col w-full text-xs">
            {missions?.map((mission: any, index: number) => (
              <tr
                key={index}
                className={`flex w-full border-[#D1D1D1] h-10 ${
                  mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                }`}
              >
                <td
                  className={`w-[800px] border-r-[1px] p-2 border-b-[1px] ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[100px] font-normal text-start pl-1 ">
                      {mission.MIS_CODE}
                    </th>
                    <th className="w-[300px] font-normal text-start pl-1 flex justify-start items-center">
                      {mission?.MIS_LIBELLE_WARNING === true ? (
                        <p className="text-lg  ">❗️</p>
                      ) : null}
                      {mission.MIS_LIBELLE}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {dateFormattedFr(mission.MIS_DEBUT)}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.MIS_FIN === null
                        ? ""
                        : dateFormattedFr(mission.MIS_FIN)}
                    </th>
                    <th className="w-[50px] font-normal text-start pl-1">
                      {mission.MIS_ACTEUR}
                    </th>
                    <th className="w-[50px] font-normal text-start pl-1">
                      {mission.MIS_COLLABORATEUR}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.MIS_REF}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[300px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[100px] font-normal text-start pl-1 flex justify-start items-center">
                      {mission.TEMPS_BUD_TPS_WARNING === true ? (
                        <p className="text-lg  ">❗️</p>
                      ) : null}
                      {mission.TEMPS_BUD_TPS}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.TEMPS_TPS_PASSES}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.TEMPS_TPS_VALIDES}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[100px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[100px] font-normal text-center pl-1 flex justify-start items-center">
                      {mission?.BUDGET_BUD_HONO_WARNING === true ? (
                        <p className="text-lg ">❗️</p>
                      ) : null}
                      {mission.BUDGET_BUD_HONO}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[200px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.VALO_TPS_PASSES}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.VALO_TPS_VALIDES}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[300px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[150px] font-normal text-start pl-1">
                      {mission.CAB_ACQUIS_CAB}
                    </th>
                    <th className="w-[150px] font-normal text-start pl-1">
                      {mission.CAB_BUDGET_CAB}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[300px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[150px] font-normal text-start pl-1">
                      {mission.ACQUIS_AVANCEMENT}
                    </th>
                    <th className="w-[150px] font-normal text-start pl-1 flex justify-start items-center">
                      {mission.ACQUIS_RESTE_A_FAIRE_WARNING === true ? (
                        <p className="text-lg  ">❗️</p>
                      ) : null}
                      {mission.ACQUIS_RESTE_A_FAIRE}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[300px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.FACTURATION_HONO_FACT}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.FACTURATION_DI_FACT}
                    </th>
                    <th className="w-[100px] font-normal text-start pl-1">
                      {mission.FACTURATION_DE_FACT}
                    </th>
                  </tr>
                </td>
                <td
                  className={`w-[300px] border-r-[1px] p-2 border-b-[1px]  ${
                    mission.MIS_ETAT !== 0 ? "bg-[#f2f2f2]" : null
                  }`}
                >
                  <tr className="flex w-full font-thin">
                    <th className="w-[300px] font-normal text-start pl-1">
                      {mission.ABONNEMENT}
                    </th>
                  </tr>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Mission;
