import { useEffect } from "react";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import chevron from "../../../../assets/icons/chevron-right.svg";
import Select from "react-select";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { createProvisionStore } from "../../../../store/Invoice/createProvision";
import { getAllDossiers } from "../../../../utils/folders";
import { getTVA } from "../../../../utils/Invoice/fees";
import { getMissionByDosRef } from "../../../../utils/missions";
import { getClientsByDOS, getContacts } from "../../../../utils/customerFile";
import {
  getProformaMasks,
  postDeposit,
} from "../../../../utils/Invoice/invoicing";
import { getDepositOrigins } from "../../../../utils/Invoice/origin";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import { useLoadingStore } from "../../../../store/utils/loading";
import { errorEntry, errorSendNewTime } from "../../../../utils/toaster";
import { useUserContext } from "../../../../context/UserContext";
import {
  calculateHtFromTtcWithoutQuantity,
  calculateTVAWithoutQuantity,
} from "../../../../utils/functionService";
/**
 * @component
 * @name CreateProvision
 * @description Ce composant permet de créer une provision
 * @author Iris Succi
 * @date 2023
 * @returns {JSX.Element} - The rendered component.
 */
const CreateProvision = () => {
  const { animationClass, setAnimationClass, displayDiv, setDisplayDiv } =
    useCreateInvoiceStore();
  const { setChoiceTab, setSendData } = useTabsStore();
  const { setIsLoading } = useLoadingStore();
  const { id } = useUserContext();
  const { createProvision, setCreateProvision } = useCreateFeesOrInvoices();
  const {
    clientsFile,
    setClientsFile,
    missions,
    setMissions,
    clients,
    setClients,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    selectedClient,
    setSelectedClient,
    origins,
    setOrigins,
    selectedOrigin,
    setSelectedOrigin,
    ns,
    setNs,
    startDate,
    setStartDate,
    ht,
    setHt,
    tvaRate,
    setTvaRate,
    tva,
    setTva,
    ttc,
    setTtc,
    selectedTvaRate,
    setSelectedTvaRate,
    setInterlocuteurs,
    libelles,
    setLibelles,
    selectedLibelle,
    setSelectedLibelle,
    comment,
    setComment,
    masks,
    setMasks,
    selectedMask,
    setSelectedMask,
    dataProvisional,
    setDataProvisional,
  } = createProvisionStore();

  /**
   * @function
   * @author Iris Succi
   * @date 2023
   * @description Gere l'affichage de la creation d une provision
   */
  useEffect(() => {
    if (createProvision === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createProvision]);

  /**
   * @description Call api pour recupérer les dossiers, les masques, les taux de tva et les origines de dépôt
   * @author Iris Succi
   * @date 2023
   */
  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.DOS_REF,
          label: file.FIC_TITRE_CLIENT,
        }));
        setClientsFile(formattedFiles);
      })
      .catch((err) => console.log(err));

    getProformaMasks()
      .then((res) => {
        const formattedFiles = Object.entries(res.data).map(([key, value]) => ({
          value: key,
          label: value,
        }));
        setMasks(formattedFiles);
      })
      .catch((err) => console.log(err));

    getTVA()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.TVA_CLE,
          label: file.TVA_VALEUR,
        }));
        setTvaRate(formattedFiles);
      })
      .catch((err) => console.log(err));

    getDepositOrigins()
      .then((res) => {
        const formattedOrigins = res.data.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
          libelles: origin.Libelles.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_LIBELLE,
          })),
        }));
        setOrigins(formattedOrigins);
      })
      .catch((err) => console.log(err));
  }, [createProvision]);

  /**
   * @description Call api pour recupérer les missions, les clients et les contacts en fonction du dossier selectionné
   * @author Iris Succi
   * @date 2023
   * @param {number} selectedFile - The ID of the selected dossier
   */
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissionByDosRef(selectedFile)
        .then((res) => {
          const formattedMissions = res.data.map((mission: any) => {
            let label = mission.MIS_LIBELLE;

            // Retire tout ce qui est entre parenthèses, y compris les parenthèses elles-mêmes
            label = label.replace(/\s*\(.*?\)\s*/g, "");

            if (mission.MIS_REF !== null && mission.MIS_DEBUT) {
              label =
                new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
                " - " +
                label;
            }
            return {
              value: mission.MIS_REF,
              label: label,
            };
          });
          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));

      getClientsByDOS(selectedFile)
        .then((res) => {
          const formattedFiles = res.data.map((file: any) => ({
            value: file.FIC_REF,
            label: file.FIC_TITRE_ETAT,
          }));
          setClients(formattedFiles);

          setSelectedClient(formattedFiles[0]?.value || 0);
          setDataProvisional("FIC_REF", formattedFiles[0]?.value || 0);
        })
        .catch((err) => console.log(err));

      getContacts(selectedFile)
        .then((res) => {
          const formattedFiles = res?.data.map((contact: any) => ({
            value: contact.DCLE,
            label: contact.name + " " + contact.firstname,
          }));
          setInterlocuteurs(formattedFiles);
        })
        .catch((err) => console.log(err));
    }
    setDataProvisional("ANA_REDACTEUR", id);
    setDataProvisional("ANA_DATEREALISATION", new Date());
  }, [selectedFile]);

  /**
   * @function
   * @name handleOriginChange
   * @author Iris Succi
   * @date 2023
   * @description Cette fonction permet de gérer le changement d'origine
   */
  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOrigin(selectedOrigin);
    setDataProvisional("AOR_REF", selectedOrigin);
    const selectedOriginObj = origins.find(
      (origin) => origin.value.toString() === selectedOrigin
    );
    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }
  };

  /**
   * @function
   * @name handleCreateProvisional
   * @author Iris Succi
   * @date 2023
   * @description Creation d'une provision
   */
  const handleCreateProvisional = async () => {
    if (selectedFile === 0 || ttc === 0 || ht === 0) {
      return errorEntry();
    }
    setIsLoading(true);
    try {
      const response = await postDeposit(dataProvisional);
      console.log("Succès:", response);
      setCreateProvision(false);
      setChoiceTab("Provision");
      setIsLoading(false);
      setSendData((prev) => !prev);
    } catch (error) {
      console.error("Erreur:", error);
      setIsLoading(false);
      errorSendNewTime();
    }
  };

  /**
   * @function
   * @name reinitializeFields
   * @author Iris Succi
   * @date 2023
   * @description Reset les champs du formulaire
   */
  const reinitializeFields = () => {
    setSelectedFile(0);
    setSelectedLibelle("");
    setSelectedMission(0);
    setSelectedOrigin("");
    setSelectedTvaRate(2);
    setHt(0);
    setTva(0);
    setTvaRate([]);
    setTtc(0);
    setNs(0);
    setComment("");
    setSelectedClient(0);
  };

  /**
   * @function
   * @name handleInputChange
   * @author Succi Iris
   * @date 2024
   * @description Fonction qui permet de gérer les changements des inputs
   */
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "ht") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setTtc(0);
        setDataProvisional("ANA_EMTTVA", 0);
        setDataProvisional("ANA_EMTTTC", 0);
      } else {
        const { newTva, newTtc } = calculateTVAWithoutQuantity(
          fieldValue,
          selectedTvaRate
        );
        setTva(newTva.toFixed(2));
        setTtc(newTtc);
        setDataProvisional("ANA_EMTTVA", newTva.toFixed(2));
        setDataProvisional("ANA_EMTTTC", newTtc.toFixed(2));
      }
    } else if (name === "ttc") {
      const fieldValue = value === "" ? "" : parseFloat(value);

      if (fieldValue === "" || fieldValue === 0) {
        setTva(0);
        setHt(0);
        setDataProvisional("ANA_EMTHT", 0);
        setDataProvisional("ANA_EMTTVA", 0);
      } else {
        const { newTva, newHt } = calculateHtFromTtcWithoutQuantity(
          fieldValue,
          selectedTvaRate
        );
        setTva(newTva.toFixed(2));
        setHt(newHt.toFixed(2));
        setDataProvisional("ANA_EMTTVA", newTva.toFixed(2));
        setDataProvisional("ANA_EMTHT", newHt.toFixed(2));
      }
    } else if (name === "txTva") {
      const fieldValue = parseFloat(value);
      setSelectedTvaRate(fieldValue);
      setDataProvisional("TVA_CLE", fieldValue);
    }
  };

  useEffect(() => {
    handleInputChange({ target: { name: "ht", value: ht } });
  }, [selectedTvaRate]);

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => setCreateProvision(false)}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-center items-start w-[80%] h-full m-auto">
              <p className="font-bold text-lg my-5 text-center w-full">
                Création d'une provision
              </p>
              <div className="grid grid-cols-7 w-full m-auto gap-4">
                <div className="col-start-1 col-end-2 ">Compteur :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="masks"
                    id="masks"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedMask}
                    onChange={(event) => {
                      setSelectedMask(event.target.value);
                      setDataProvisional("mask", event.target.value);
                    }}
                  >
                    <option value={""}></option>
                    {masks?.map((mask, i) => (
                      <option key={i} value={mask?.value}>
                        {mask?.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">Dossier :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <Select
                    options={clientsFile}
                    isClearable
                    className="w-full h-9 z-50 "
                    isSearchable
                    onChange={(selectedFile: any | null) => {
                      setSelectedFile(selectedFile?.value || 0);
                      setDataProvisional("DOS_REF", selectedFile?.value || 0);
                    }}
                  />
                </div>
                <div className="col-start-1 col-end-2  mt-1">Mission :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="mission"
                    id="mission"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-40 "
                    value={selectedMission}
                    onChange={(event) => {
                      setSelectedMission(Number(event.target.value));
                      setDataProvisional("MIS_REF", Number(event.target.value));
                    }}
                  >
                    <option value={""}></option>
                    {missions?.map((mission, i) => (
                      <option key={i} value={mission?.value}>
                        {mission?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 mt-1">Client :</div>
                <div className="flex flex-col col-start-6 col-end-8 z-30">
                  <select
                    name="Client"
                    id="Client"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-30 "
                    value={selectedClient}
                    onChange={(event) => {
                      setSelectedClient(parseInt(event.target.value));
                      setDataProvisional(
                        "FIC_REF",
                        parseInt(event.target.value)
                      );
                    }}
                  >
                    <option value={""}></option>
                    {clients?.map((client, i) => (
                      <option key={i} value={client?.value}>
                        {client?.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* <div className="col-start-5 col-end-6">Responsable :</div>
                <div className="flex col-start-6 col-end-8">
                  <select
                    name="Responsable"
                    id="Responsable"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedResponsable}
                    onChange={(event) => {
                      setSelectedResponsable(parseInt(event.target.value));
                    }}
                  >
                    <option value={""}></option>
                    {responsables?.map((responsable, i) => (
                      <option key={i} value={responsable?.value}>
                        {responsable?.label}
                      </option>
                    ))}
                  </select>
                </div> */}

                {/* <div className="col-start-5 col-end-6 mt-1">Interloc. :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="Interloc"
                    id="Interloc"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedInterlocuteur}
                    onChange={(event) => {
                      setSelectedInterlocuteur(parseInt(event.target.value));
                    }}
                  >
                    <option value={""}></option>
                    {interlocuteurs?.map((interlocuteur, i) => (
                      <option key={i} value={interlocuteur?.value}>
                        {interlocuteur?.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
                <div className="col-start-1 col-end-2 ">Origine :</div>
                <div className="flex flex-col col-start-2 col-end-4">
                  <select
                    name="Origine"
                    id="Origine"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedOrigin}
                    onChange={handleOriginChange}
                  >
                    <option value={""}></option>
                    {origins?.map((origin, i) => (
                      <option key={i} value={origin?.value}>
                        {origin?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-5 col-end-6 ">Libelle :</div>
                <div className="flex flex-col col-start-6 col-end-8">
                  <select
                    name="Libelle"
                    id="Libelle"
                    className="w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
                    value={selectedLibelle}
                    onChange={(event) => {
                      setSelectedLibelle(event.target.value);
                      setDataProvisional("ANA_CODELIB", event.target.value);
                      console.log(selectedLibelle);
                    }}
                  >
                    <option value={""}></option>
                    {libelles?.map((libelle, i) => (
                      <option key={i} value={libelle?.value}>
                        {libelle?.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-start-1 col-end-2 mt-1">Comm. :</div>
                <textarea
                  name="Comm"
                  id="Comm"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setDataProvisional("ANA_NOTES", e.target.value);
                  }}
                  className="col-start-2 col-end-4 border-[1px] rounded border-gray-300 h-20 py-1 z-20 "
                />
                <div className="col-start-5 col-end-6 mt-1">Date facture:</div>
                <div className="flex flex-col col-start-6 col-end-8 z-10">
                  <Datepicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                  />
                </div>
              </div>
              <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              <div className="grid grid-cols-10 gap-2 w-full">
                <div className="col-start-1 col-end-2 mt-1 text-sm ml-4">
                  H.T :
                </div>
                <input
                  type="number"
                  name="ht"
                  value={ht}
                  className="w-full border-[1px] rounded border-gray-300 h-8"
                  onChange={(e) => {
                    const newHt = parseInt(e.target.value);
                    setHt(newHt);
                    setDataProvisional("ANA_EMTHT", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
                <div className="col-start-3 col-end-4 mt-1 text-sm">
                  TX TVA :
                </div>
                <select
                  name="txTva"
                  id="txTva"
                  className="w-full text-sm border-[1px] rounded border-gray-300 h-8 py-1 z-20 "
                  value={selectedTvaRate}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSelectedTvaRate(Number(e.target.value));
                    setDataProvisional("TVA_CLE", Number(e.target.value));
                  }}
                >
                  <option value={""}></option>
                  {tvaRate?.map((tvaRate, i) => (
                    <option key={i} value={tvaRate?.value}>
                      {tvaRate?.label} %
                    </option>
                  ))}
                </select>
                <div className="col-start-5 text-sm col-end-6 mt-1 ml-3">
                  TVA :
                </div>
                <input
                  type="number"
                  readOnly
                  value={tva}
                  className="w-full border-[1px] rounded border-gray-300 h-8"
                  onChange={(e) => {
                    setTva(parseInt(e.target.value));
                    setDataProvisional("ANA_EMTTVA", Number(e.target.value));
                  }}
                />
                <div className="col-start-7 col-end-8 text-sm mt-1 ml-4">
                  NS :
                </div>
                <input
                  type="number"
                  value={ns}
                  className="w-full border-[1px] rounded border-gray-300 h-8"
                  onChange={(e) => {
                    setNs(parseInt(e.target.value));
                    setDataProvisional("ANA_EMTNS", Number(e.target.value));
                  }}
                />
                <div className="col-start-9 col-end-10 text-sm mt-1 ml-3">
                  T.T.C :
                </div>
                <input
                  type="number"
                  name="ttc"
                  value={ttc}
                  className="w-full border-[1px] rounded border-gray-300 h-8"
                  onChange={(e) => {
                    setTtc(parseInt(e.target.value));
                    setDataProvisional("ANA_EMTTTC", Number(e.target.value));
                    handleInputChange(e);
                  }}
                />
              </div>
              <hr className="w-full border-[1px] col-start-1 col-end-8 my-4 " />
              <div className="flex justify-center items-center ml-2">
                <input type="checkbox" name="" id="" className="mr-4" />
                <p className="mr-10">Ne pas déduire</p>
                <input type="checkbox" name="" id="" className="mr-4" />
                <p>Déjà réglé</p>
              </div>
              <div className="grid grid-cols-7 w-full m-auto gap-4">
                <button
                  className="col-start-6 col-end-7 w-full h-8 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                  onClick={() => {
                    setChoiceTab("Provision");
                    setCreateProvision(false);
                    reinitializeFields();
                  }}
                >
                  Annuler{" "}
                </button>
                <button
                  className="col-start-7 col-end-8 w-full h-8 mr-4 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                  onClick={() => {
                    setCreateProvision(false);
                    handleCreateProvisional();
                  }}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateProvision;
