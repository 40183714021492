import { create } from "zustand";
import { SelectOptionDossier } from "../../interface/selects";
type DataProformaState = {
  ANA_CODELIB: string | null,
  ANA_DATEREALISATION: Date | null,
  ANA_REFs: [] | null,
  AOR_REF: number | null,
  DOS_REF: number | null,
  MIS_REF: number | null,
  FIC_REF_CLIENT: number | null,
  FIC_REF_INTERLOCUTEUR: number | null,
  mask: any | null,
}

type DataProformaUpdateState = {
  ANA_CODELIB: string | null,
  ANA_DATEREALISATION: Date | null,
  ANA_REFs: [] | null,
  AOR_REF: number | null,
  DOS_REF: number | null,
  MIS_REF: number | null,
  FIC_REF_CLIENT: number | null,
  FIC_REF_INTERLOCUTEUR: number | null,
  ANA_FACTURE: number | null,
  mask: any | null,
}

interface MyState {
    clientsFile: any[];
    setClientsFile: (clientsFile: any[]) => void;

    startDate: Date;
    setStartDate: (startDate: Date) => void;

    interlocutors: any[];
    setInterlocutors: (interlocutor: any[]) => void;

    selectedInterlocutor: number;
    setSelectedInterlocutor: (interlocutor: number) => void;

    selectedFile: number;
    setSelectedFile: (file: number) => void;

    selectedOrigin: any;
    setSelectedOrigin: (origin: any) => void;

    selectedLibelle: any;
    setSelectedLibelle: (libelle: any) => void;

    origins: any[];
    setOrigins: (origins: any[]) => void;

    libelles: any[];
    setLibelles: (libelles: any[]) => void;

    masks : any[];
    setMasks: (masks: any[]) => void;

    selectedMask: string;
    setSelectedMask: (mask: string) => void;

    clients: any[];
    setClients: (clients: any[]) => void;

    selectedClient: number;
    setSelectedClient: (selectedClient: any) => void;

    dataProforma : DataProformaState;
    setDataProforma: (property: keyof DataProformaState, value: any) => void;

    modeProforma: string;
    setModeProforma: (mode: string) => void;

    proformaDataUpdate: DataProformaUpdateState;
    setProformaDataUpdate: (data: DataProformaUpdateState | ((prevState: DataProformaUpdateState) => DataProformaUpdateState)) => void;

    idsFeesForUpdate: number[];
    setIdsFeesForUpdate: (idFees: number[]) => void;

    feesForUpdateToLinkProforma: any[];
    setFeesForUpdateToLinkProforma: (fees: any[]) => void;

    feesForUpdateToUnlinkProforma: any[];
    setFeesForUpdateToUnlinkProforma: (fees: any[]) => void;

    checkedFees: any[];
    setCheckedFees: (fees: any[]) => void;

    feeAutomatic: any;
    setFeeAutomatic: (fees: any) => void;

    boolFeeAutomatic: boolean;
    setBoolFeeAutomatic: (bool: boolean) => void;

  }
  
  export const useCreateProformaStore = create<MyState>((set) => ({
    clientsFile: [],
    setClientsFile: (clientsFile: any[]) => set({ clientsFile }),

    startDate: new Date(),
    setStartDate: (startDate: Date) => set({ startDate }),

    selectedFile: 0,
    setSelectedFile: (file: number) => set({ selectedFile: file }),

    selectedOrigin: '',
    setSelectedOrigin: (origin: any) => set({ selectedOrigin: origin }),

    selectedLibelle: '',
    setSelectedLibelle: (libelle: any) => set({ selectedLibelle: libelle }),

    origins: [],
    setOrigins: (origins: SelectOptionDossier[]) => set({ origins }),

    libelles: [],
    setLibelles: (libelles: SelectOptionDossier[]) => set({ libelles }),
  
    masks: [],
    setMasks: (masks: any[]) => set({ masks }),

    selectedMask: '',
    setSelectedMask: (mask: string) => set({ selectedMask: mask }),

    interlocutors: [],
    setInterlocutors: (interlocutors: any[]) => set({ interlocutors }),

    selectedInterlocutor: 0,
    setSelectedInterlocutor: (interlocutor: number) => set({ selectedInterlocutor: interlocutor }),

    clients: [],
    setClients: (clients: any[]) => set({ clients }),

    selectedClient: 0,
    setSelectedClient: (selectedClient: any) => set({ selectedClient }),

    dataProforma: {
      ANA_CODELIB: "",
      ANA_DATEREALISATION: new Date(),
      ANA_REFs: [],
      AOR_REF: 0,
      DOS_REF: 0,
      MIS_REF: 0,
      FIC_REF_CLIENT: 0,
      mask: "",
      FIC_REF_INTERLOCUTEUR: 0,
    },
    setDataProforma: (property, value) => set((state) => ({ dataProforma: { ...state.dataProforma, [property]: value } })),

    modeProforma: 'create',
    setModeProforma: (mode) => set({ modeProforma: mode }),

    proformaDataUpdate: {
      ANA_CODELIB: "",
      ANA_DATEREALISATION: new Date(),
      ANA_REFs: [],
      AOR_REF: 0,
      DOS_REF: 0,
      MIS_REF: 0,
      FIC_REF_CLIENT: 0,
      mask: "",
      FIC_REF_INTERLOCUTEUR: 0,
      ANA_FACTURE: 0,
    },
    setProformaDataUpdate: (data) => set((state) => ({ proformaDataUpdate: { ...state.proformaDataUpdate, ...data } })),
  
    idsFeesForUpdate: [],
    setIdsFeesForUpdate: (idFees) => set({ idsFeesForUpdate: idFees }),

    feesForUpdateToLinkProforma: [],
    setFeesForUpdateToLinkProforma: (fees) => set({ feesForUpdateToLinkProforma: fees }),

    feesForUpdateToUnlinkProforma: [],
    setFeesForUpdateToUnlinkProforma: (fees) => set({ feesForUpdateToUnlinkProforma: fees }),

    checkedFees: [],
    setCheckedFees: (fees) => set({ checkedFees: fees }),

    feeAutomatic: {},
    setFeeAutomatic: (fees) => set({ feeAutomatic: fees }),

    boolFeeAutomatic: false,
    setBoolFeeAutomatic: (bool) => set({ boolFeeAutomatic: bool }),
  }));
  