import { create } from "zustand";

type myState = {
    startDate: Date;
    setStartDate: (startDate: Date) => void;

    selectedBankSlip: any[];
    setSelectedBankSlip: (selectedBankSlip: any) => void;

    slipDocument: any;
    setSlipDocument: (slipDocument: any) => void;

    abaRef: any;
    setAbaRef: (abaRef: any) => void;

    operation: string;
    setOperation: (operation: string) => void;

    modes: any;
    setModes: (modes: any) => void;

    selectedMode: any;
    setSelectedMode: (selectedMode: any) => void;

    type: any;
    setType: (type: any) => void;

    selectedOption: string;
    setSelectedOption: (selectedOption: string) => void;

    slips: any;
    setSlips: (slips: any) => void;

    targetDate: any;
    setTargetDate: (targetDate: any) => void;

    slipFormData: any;
    setSlipFormData: (slipFormData: any) => void;

    verifySchedule: boolean;
    setVerifySchedule: (verifySchedule: boolean) => void;

    update: boolean;
    setUpdate: (update: boolean) => void;

    ribs: any;
    setRibs: (ribs: any) => void;

    selectedRib: any;
    setSelectedRib: (selectedRib: any) => void;

    mandats: any;
    setMandats: (mandats: any) => void;

    selectedMandat: any;
    setSelectedMandat: (selectedMandat: any) => void;

    reset: () => void;
}

export const useSlip = create<myState>((set) => ({
    startDate: new Date(),
    setStartDate: (startDate) => set({ startDate }),

    selectedBankSlip: [],
    setSelectedBankSlip: (selectedBankSlip) => set({ selectedBankSlip }),

    slipDocument: "",
    setSlipDocument: (slipDocument) => set({ slipDocument }),

    abaRef: 0,
    setAbaRef: (abaRef) => set({ abaRef }),

    operation: "remise en banque",
    setOperation: (operation) => set({ operation }),

    modes: [],
    setModes: (modes) => set({ modes }),

    selectedMode: 0,
    setSelectedMode: (selectedMode) => set({ selectedMode }),

    type: [],
    setType: (type) => set({ type }),

    selectedOption: "simple",
    setSelectedOption: (selectedOption) => set({ selectedOption }),

    slips: [],
    setSlips: (slips) => set({ slips }),

    targetDate: new Date(),
    setTargetDate: (targetDate) => set({ targetDate }),

    slipFormData: {},
    setSlipFormData: (slipFormData) => set({ slipFormData }),

    verifySchedule: false,
    setVerifySchedule: (verifySchedule) => set({ verifySchedule }),

    update: false,
    setUpdate: (update) => set({ update }),

    ribs: [],
    setRibs: (ribs) => set({ ribs }),

    selectedRib: 0,
    setSelectedRib: (selectedRib) => set({ selectedRib }),

    mandats: [],
    setMandats: (mandats) => set({ mandats }),

    selectedMandat: 0,
    setSelectedMandat: (selectedMandat) => set({ selectedMandat }),

    reset: () => set({
        startDate: new Date(),
        selectedBankSlip: [],
        slipDocument: "",
        abaRef: 0,
        operation: "remise en banque",
        modes: [],
        type: [],
        selectedOption: "simple",
        slips: [],
        targetDate: new Date(),
        slipFormData: {},
        selectedMode: 0,
        verifySchedule: false,
        update: false,
        ribs: [],
        mandats: [],
        selectedRib: 0,
        selectedMandat: 0,
    }),
}));