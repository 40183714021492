import { useEffect, useRef } from "react";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { useLoadingStore } from "../../../store/utils/loading";
import { usePagination } from "../../../hooks/usePagination";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import { formatNumber } from "../../../utils/functionService";
import CreateSlip from "../modal/Create/CreateSlip";
import { useCreateFeesOrInvoices } from "../../../store/Invoice/buttonsStore";
import VisuPdfModal from "../modal/VisuPdfModal";
import { useSlip } from "../../../store/Invoice/createSlip";
import TableVerifySchedule from "../modal/Create/TableVerifySchedule";

/**
 * @Component SlipTable
 * @author Succi Iris
 * @date 2023
 * @description Ce composant affiche un tableau de bordereaux, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche.
 * @returns {JSX.Element} - The rendered component
 */
const SlipTable = () => {
  const { slips, slipsFilter, setSlipsFilter } = dataInvoicesFeelsStore();
  const {
    displayDiv,
    displayDivVisuPdf,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
  } = useCreateInvoiceStore();
  const { search } = useSearchBarStore();
  const { createSlip } = useCreateFeesOrInvoices();
  const { isLoading } = useLoadingStore();
  const { setAbaRef, setType, verifySchedule, setSelectedMode, setStartDate } =
    useSlip();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(slipsFilter, 30);

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    ABA_REF: number,
    date: Date,
    type: string,
    AMR_REF: number
  ) => {
    const selectedInvoice = { ABA_REF, date, type, AMR_REF };
    if (e.target.checked) {
      // Ajouter l'objet contenant la date et ABA_REF
      setSelectedIdsInvoices((prev) => [...prev, selectedInvoice]);
      setAbaRef(selectedInvoice.ABA_REF);
      setType(selectedInvoice.type);
      setSelectedMode(selectedInvoice.AMR_REF);
      setStartDate(new Date(selectedInvoice.date));
    } else {
      // Retirer l'objet correspondant
      setSelectedIdsInvoices((prev) =>
        prev.filter(
          (inv) =>
            inv.date !== date || inv.ABA_REF !== ABA_REF || inv.type !== type
        )
      );
    }
  };

  const isSelected = (ABA_REF: any, date: any, type: any) => {
    return selectedIdsInvoices.some(
      (inv) => inv.date === date && inv.ABA_REF === ABA_REF && inv.type === type
    );
  };

  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  useEffect(() => {
    let data = slips?.length > 0 ? [...slips] : [...slipsFilter];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["bank"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["type"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["amount"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA; // Trie du plus récent au plus ancien
    });

    setSlipsFilter(data);
  }, [search, slips]);

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {verifySchedule && <TableVerifySchedule />}
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : slips.length > 0 ? (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="flex w-full bg-[#cecece] border-b-[1px] sticky top-0">
                <tr className="w-[2%] border-r-[1px] flex justify-center items-center"></tr>
                <tr className="w-[20%] border-r-[1px] p-2">Type</tr>
                <tr className="w-[15%] border-r-[1px] p-2">Date</tr>
                <tr className="w-[20%] border-r-[1px] p-2">Banque emission</tr>
                <tr className="w-[20%] border-r-[1px] p-2">Transaction</tr>
                <tr className="w-[10%] border-r-[1px] p-2">Montant</tr>
                <tr className="w-[10%] p-2">Mode</tr>
              </thead>
              <tbody className="flex flex-col w-full text-xs">
                {filteredData?.map((slip, index) => (
                  <tr
                    key={index}
                    className={`w-full border-b-[1px] flex ${
                      index % 2 === 0 ? "bg-grey-Array" : ""
                    }`}
                  >
                    <td className="w-[2%] border-r-[1px] flex justify-center items-start pt-2">
                      <input
                        type="checkbox"
                        value={slip.ABA_REF}
                        checked={isSelected(slip.ABA_REF, slip.date, slip.type)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            slip.ABA_REF,
                            slip.date,
                            slip.type,
                            slip.AMR_REF
                          )
                        }
                      />
                    </td>
                    <td className="w-[20%] border-r-[1px] p-2">{slip?.type}</td>

                    <td className="w-[15%] border-r-[1px] p-2">
                      {dateFormattedFr(slip?.date)}
                    </td>
                    <td className="w-[20%] border-r-[1px] p-2">{slip?.bank}</td>
                    <td className="w-[20%] border-r-[1px] p-2">
                      {slip?.transactions}
                    </td>
                    <td className="w-[10%] border-r-[1px] p-2">
                      {formatNumber(slip?.amount)}
                    </td>
                    <td className="w-[10%] p-2"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucun bordereau</p>
        )}
        {createSlip ? <CreateSlip /> : null}
        {displayDivVisuPdf ? <VisuPdfModal /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};

export default SlipTable;
