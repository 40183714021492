import { create } from "zustand";

type ExplorerState = {
    classeurs: any[];
    setClasseurs: (classeurs: any[]) => void;

    dossiers: any[];
    setDossiers: (dossiers: any[]) => void;

    ficRef: number;
    setFicRef: (ficRef: number) => void;

    DCLE: number;
    setDCLE: (DCLE: number) => void;

    dosRef: number;
    setDosRef: (dosRef: number) => void;

    PDOS_ID: number;
    setPDOS_ID: (PDOS_ID: number) => void;

    PENT_ID: number;
    setPENT_ID: (PENT_ID: number) => void;

    participantsCategories: any[];
    setParticipantsCategories: (participantsCategories: any[]) => void;

    participants: any[];
    setParticipants: (participants: any[]) => void;

    clientFileName: string;
    setClientFileName: (clientFileName: string) => void;

    documentUrl: string;
    setDocumentUrl: (documentUrl: string) => void;

    numberDoss: number;
    setNumberDoss: (numberDoss: number) => void;

    ficRefIntervenant: number;
    setFicRefIntervenant: (ficRefIntervenant: number) => void;

    count: number;
    setCount: (count: number) => void;

    resetExplorer: () => void;
}

export const useExplorer = create<ExplorerState>((set) => ({
    classeurs: [],
    setClasseurs: (classeurs) => set({ classeurs }),

    dossiers: [],
    setDossiers: (dossiers) => set({ dossiers }),

    ficRef: 0,
    setFicRef: (ficRef) => set({ ficRef }),

    DCLE: 0,
    setDCLE: (DCLE) => set({ DCLE }),

    dosRef: 0,
    setDosRef: (dosRef) => set({ dosRef }),

    PDOS_ID: 0,
    setPDOS_ID: (PDOS_ID) => set({ PDOS_ID }),

    PENT_ID: 0,
    setPENT_ID: (PENT_ID) => set({ PENT_ID }),

    participantsCategories: [],
    setParticipantsCategories: (participantsCategories) => set({ participantsCategories }),

    participants: [],
    setParticipants: (participants) => set({ participants }),

    clientFileName: "",
    setClientFileName: (clientFileName) => set({ clientFileName }),

    documentUrl: "",
    setDocumentUrl: (documentUrl) => set({ documentUrl }),

    numberDoss: 0,
    setNumberDoss: (numberDoss) => set({ numberDoss }),

    ficRefIntervenant: 0,
    setFicRefIntervenant: (ficRefIntervenant) => set({ ficRefIntervenant }),

    count: 0,
    setCount: (count) => set({ count }),

    resetExplorer: () => set({
        classeurs: [],
        dossiers: [],
        ficRef: 0,
        DCLE: 0,
        dosRef: 0,
        PDOS_ID: 0,
        PENT_ID: 0,
        participantsCategories: [],
        participants: [],
        clientFileName: "",
        documentUrl: "",
        numberDoss: 0,
        ficRefIntervenant: 0,
        count: 0,
    }),
}));
