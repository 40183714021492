import axios from "axios";
import { axiosWithToken } from "./axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / put customerfile
 */

export const getVarDos = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/varfile`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
};

export const getVarDosLib = async (number: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/varfilelib?number=${number}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getContacts = async (DOS_REF: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${DOS_REF}/contacts`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getClients = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/customers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getClientsByDOS = async (DOS_REF: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${DOS_REF}/customer`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getPostTitle = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/posttitle`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getPersonnesFile = async (ficRef: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${ficRef}/personnesfile`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const postPersonnesFile = async (data: any) => {
    try {
        const response = await axiosWithToken().post(`api/customerfile/customer`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getJuridicForm = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/juridicform`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const patchPersonnesFile = async (DCLE: number, data: any) => {
    try {
        const response = await axiosWithToken().patch(`api/customerfile/customer/${DCLE}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getParticipantsByDos = async (dos_ref: number, pent_id: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/participants?PENT_ID=${pent_id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getInvoicesCheckablesForPrepayment = async (dos_ref: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/invoices/checkables`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getDocumentsByDos = async (dos_ref: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/documents`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue")
          }
          throw error;
    }
}

export const getLevelRelaunchs = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/relance`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getMissionsDetailedByDosRef = async (dos_ref: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/missions/detailed`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getSuppliersByDosRef = async (dos_ref: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/suppliers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getCustomerByDosRef = async (dos_ref: number) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/customers`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getVarFileDossier = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/varfile`)
        return response
    }  catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getParagraphes = async (dos_ref: number) => {
    try {
      const response = await axiosWithToken().get(`api/customerfile/${dos_ref}/paragraphes`);
      return response; 
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data || "Une erreur est survenue");
      }
      throw error;
    }
  };
  
export const updateParagraphes = async(dos_ref: number, paragraphes: any) => {
    try {
        const response = await axiosWithToken().patch(`api/customerfile/${dos_ref}/paragraphes`, paragraphes)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getImplicitGroups = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/implicitgroups`);
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}


export const getRegimeTva = async () => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/regimevat`);
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getFiels = async (PFIC_ID: any,PENT_ID: any, PDOS_ID: any) => {
    try {
        const response = await axiosWithToken().get(`api/customerfile/customer/fields?PFIC_ID=${PFIC_ID}&PENT_ID=${PENT_ID}&PDOS_ID=${PDOS_ID}`);
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}