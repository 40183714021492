import { create } from 'zustand';

type ClientFile = {
    filesModel: any;
    setFilesModel: (filesModel: any) => void;
    
    selectedFile: any;
    setSelectedFile: (selectedFile: any) => void;

    cp: string;
    setCp: (cp: string) => void;

    city: string;
    setCity: (city: string) => void;

    fileNumber: number;
    setFileNumber: (fileNumber: number) => void;

    updateData: boolean;
    setUpdateData: (updateData: boolean) => void;

    numberOfFileDos: number;
    setNumberOfFileDos: (numberOfFileDos: number) => void;

    varFilesDossier: any;
    setVarFilesDossier: (varFilesDossier: any) => void;

    varFiches: any;
    setVarFiches : (varFiches: any) => void;

    pentId: number;
    setPentId: (pentId: number) => void;

    openParagraphes: boolean;
    setOpenParagraphes: (penParagraphes: boolean) => void;

    paragraphes: any;
    setParagraphes: (paragraphes: any) => void;

    implicitGroups: any;
    setImplicitesGroupes: (implicitGroup: any) => void;

    selectedGroup: string;
    setSelectedGroup: (selectedGroup: string) => void;

    selectedTvaRegime: any;
    setSelectedTvaRegime: (selectedTvaRegime: any) => void;

    fields: any;
    setFields: (fileds: any) => void;

    data: { [key: string]: string }; // Structure clé-valeur
    setData: (newData: { [key: string]: string }) => void; 

    localCp: string;
    setLocalCp: (localCp: string) => void;

    localCity: string;
    setLocalCity: (localCity: string) => void;

    selectedValues: { [key: string]: string };
    setSelectedValues: (key: string, value: string) => void;

    inputValues: { [key: string]: string };
    setInputValues: (key: string, value: string) => void;

    resetClientFile: () => void;
}

export const useClientFileStore = create<ClientFile>((set) => ({
    filesModel: [],
    setFilesModel: (filesModel) => set({ filesModel }),

    selectedFile: '',
    setSelectedFile: (selectedFile) => set({ selectedFile }),

    cp: '',
    setCp: (cp) => set({ cp }),

    city: '',
    setCity: (city) => set({ city }),

    fileNumber: 0,
    setFileNumber: (fileNumber) => set({ fileNumber }),

    selectedTvaRegime: "",
    setSelectedTvaRegime: (selectedTvaRegime) => set({ selectedTvaRegime }),

    updateData: false,
    setUpdateData: (updateData) => set({ updateData }),

    numberOfFileDos: 0,
    setNumberOfFileDos: (numberOfFileDos) => set({ numberOfFileDos }),

    varFilesDossier: [], 
    setVarFilesDossier: (varFilesDossier) => set({ varFilesDossier }),

    varFiches: [],
    setVarFiches: (varFiches) => set({ varFiches }),

    pentId: 0,
    setPentId: (pentId) => set({ pentId }),

    openParagraphes: false,
    setOpenParagraphes: (openParagraphes) => set({ openParagraphes }),

    paragraphes: [],
    setParagraphes: (paragraphes) => set({ paragraphes }),

    implicitGroups: [],
    setImplicitesGroupes: (implicitGroups) => set({ implicitGroups }),

    selectedGroup: "",
    setSelectedGroup: (selectedGroup) => set({selectedGroup}),

    fields: [],
    setFields: (fields) => set({ fields }),

    data: {}, // Initialisation de l'objet `data`
    setData: (newData) => set(() => ({ data: newData })),

    localCp: "",
    setLocalCp: (localCp) => set({ localCp }),

    localCity: "",
    setLocalCity: (localCity) => set({ localCity }),

    selectedValues: {},
    setSelectedValues: (key, value) =>
      set((state) => ({
        selectedValues: {
          ...state.selectedValues,
          [key]: value,
        },
      })),

    inputValues: {},
    setInputValues: (key, value) =>
      set((state) => ({
        inputValues: {
          ...state.inputValues,
          [key]: value,
        },
      })),

    resetClientFile: () => set(() => ({
        filesModel: [],
        selectedFile: '',
        cp: '',
        city: '',
        fileNumber: 0,
        updateData: false,
        numberOfFileDos: 0,
        varFilesDossier: [],
        varFiches: [],
        pentId: 0,
        openParagraphes: false,
        paragraphes: [],
        implicitGroups: [],
        selectedGroup: "",
        selectedTvaRegime: "",
        fields: [],
        data: {},
        localCp: "",
        localCity: "",
        selectedValues: {},
        inputValues: {},
    }))
}));