import chevronRight from "../../../assets/icons/chevron-right.svg";
import chevronLeft from "../../../assets/icons/chevron-left.svg";
import {
  decrementDate,
  decrementDateByDay,
  incrementDate,
  incrementDateByDay,
} from "../../../utils/datepicker";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
import { useViewStore } from "../../../store/TimeManagement/viewStore";
/**
 * @component
 * @name IncrementDate
 * @author Iris Succi
 * @date 2023
 * @description Composant pour incrémenter la date
 */
const IncrementDate = () => {
  //Store
  const { startDate, setStartDate } = useDateStore();
  const { view } = useViewStore();

  return (
    <div className="flex">
      <button
        className="lg:w-9 lg:h-9 w-6 h-6 bg-bc-orange rounded flex justify-center items-center mr-2 shadow-md hover:bg-bc-lightOrange transition-colors duration-200"
        onClick={() => {
          view === "week" || view === "edit"
            ? decrementDate(startDate, setStartDate)
            : decrementDateByDay(startDate, setStartDate);
        }}
      >
        <img src={chevronLeft} alt="Chevron gauche" className="" />
      </button>

      <button
        className="lg:w-9 lg:h-9 w-6 h-6 bg-bc-orange rounded flex justify-center items-center mr-2 shadow-md hover:bg-bc-lightOrange transition-colors duration-200"
        onClick={() => {
          view === "week" || view === "edit"
            ? incrementDate(startDate, setStartDate)
            : incrementDateByDay(startDate, setStartDate);
        }}
      >
        <img src={chevronRight} alt="Chevron droit" className="" />
      </button>
    </div>
  );
};

export default IncrementDate;
