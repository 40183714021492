import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useCreateFeesOrInvoices } from "../../../../../store/Invoice/buttonsStore";
import { useDEStore } from "../../../../../store/Invoice/createDEStore";
import { createBillableItemsStore } from "../../../../../store/Invoice/createBillableItemsStore";
import {
  getCustomerByDosRef,
  getSuppliersByDosRef,
} from "../../../../../utils/customerFile";
import AmountsForDE from "./AmountsForDE";
import {
  getBillableExternalDisbursements,
  getTarifs,
} from "../../../../../utils/Invoice/fees";
import { getAllUsersWithInactive } from "../../../../../utils/user";
import { useUserContext } from "../../../../../context/UserContext";
import { amountsForDEStore } from "../../../../../store/Invoice/amountsForDE";
import { useCreateFee } from "../../../../../store/Invoice/createFeeStore";
/**
 * @component
 * @name CreateDeboursExt
 * @author Succi Iris
 * @date 2023
 * @description Création d'un debours externe
 * @returns {JSX.Element} - The rendered component.
 */
const CreateDeboursExt = () => {
  const { billableItem } = useCreateFeesOrInvoices();
  const {
    quantityDE,
    setQuantityDE,
    setUnitPriceSell,
    setUnitPriceBuy,
    setHtBuy,
    setHtSell,
    setTvaBuy,
    setTvaSell,
    setTtcBuy,
    setTtcSell,
    setSelectedTvaRateDE,
    setNsBlocked,
    setNsBuy,
    setNsSell,
  } = amountsForDEStore();
  const { modeFee } = useCreateFee();
  const {
    suppliers,
    setSuppliers,
    selectedSupplier,
    setSelectedSupplier,
    customers,
    setCustomers,
    selectedCustomer,
    setSelectedCustomer,
    missions,
    setMissions,
    selectedMissionDE,
    setSelectedMissionDE,
    origins,
    setOrigins,
    selectedOriginDE,
    setSelectedOriginDE,
    libelles,
    setLibelles,
    selectedLibelleDE,
    setSelectedLibelleDE,
    actors,
    setActors,
    selectedActorDE,
    setSelectedActorDE,
    commentDE,
    setCommentDE,
    subscriptions,
    setSubscriptions,
    selectedSubscriptionDE,
    setSelectedSubscriptionDE,
    prices,
    setPrices,
    setPFIC_ID,
    setFIC_CLE,
    piece,
    setPiece,
    libelleCpt,
    setLibelleCpt,
  } = useDEStore();
  const { selectedFile, updateData, selectedProcessus } =
    createBillableItemsStore();
  const { id } = useUserContext();

  // Call api pour récupérer les données du formulaire
  const fetchBillableFormDatas = async () => {
    getBillableExternalDisbursements(selectedFile)
      .then((res) => {
        // MISSIONS
        const formattedMissions = res.data.Missions.map((mission: any) => ({
          label:
            new Date(mission.MIS_DEBUT).toISOString().split("T")[0] +
            " - " +
            mission.MIS_LIBELLE,
          value: mission.MIS_REF,
          origins: mission.Origines,
          abonnementId: mission.ABN_REF,
          abonnementLibelle: mission.ABN_LIBELLES,
        }));
        setMissions(formattedMissions);
        // ORIGINS
        const formattedOrigins = res?.data?.Origines.map((origin: any) => ({
          value: origin.AOR_REF,
          label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
          libelles: origin.Libelles.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
            ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
            ePrixUnitaireVente: libelle.ALI_EPRIX_VENTE,
            quantite: libelle.ALI_QUANTITE,
            nsBlock: libelle.ALI_PU_IN_NS,
          })),
        }));
        setOrigins(formattedOrigins);

        if (origins.length === 1) {
          setSelectedOriginDE(formattedOrigins[0].value);
          setLibelles(formattedOrigins[0].libelles);
          setSelectedLibelleDE(formattedOrigins[0].libelles[0].value);
        }
        // SUBSCRIPTIONS
        const formattedSubscriptions = res.data.Abonnements.map(
          (subscription: any) => ({
            label: subscription.ABN_LIBELLES,
            value: subscription.ABN_REF,
          })
        );
        setSubscriptions(formattedSubscriptions);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchBillableFormDatas();
  }, [selectedFile, selectedProcessus]);

  useEffect(() => {
    if (
      selectedMissionDE &&
      billableItem === "Debours externe" &&
      selectedMissionDE !== 0
    ) {
      const selectedMissionData = missions?.find(
        (mission) => mission.value === selectedMissionDE
      );
      // SET ORIGINS if mission has origins
      if (selectedMissionData?.origins?.length > 0) {
        const formattedOrigins = selectedMissionData?.origins?.map(
          (origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_CODE + " " + origin.AOR_LIBELLE,
            libelles: origin?.Libelle?.map((libelle: any) => ({
              value: libelle.ALI_CODE,
              label: libelle.ALI_CODE + "  " + libelle.ALI_LIBELLE,
              ePrixUnitaire: libelle.ALI_EPRIX_UNITAIRE,
              ePrixUnitaireVente: libelle.ALI_EPRIX_VENTE,
              quantite: libelle.ALI_QUANTITE,
            })),
          })
        );

        if (selectedMissionData.nsBlock === 1) {
          setSelectedTvaRateDE(3);
          setHtBuy(0);
          setHtSell(0);
          setTvaBuy(0);
          setTvaSell(0);
        }
        setOrigins(formattedOrigins);
        setSelectedOriginDE(formattedOrigins[0].value);
        setLibelles(formattedOrigins[0].libelles);
        setSelectedLibelleDE(formattedOrigins[0].libelles[0].value);
      } else {
        setLibelles([]);
        setSelectedLibelleDE("");
        fetchBillableFormDatas();
      }
      // SET SUBSCRIPTIONS if mission has abonnement
      if (selectedMissionData?.abonnementId !== null) {
        const formattedSubscriptions = [
          {
            label: selectedMissionData?.ABN_LIBELLES,
            value: selectedMissionData?.ABN_REF,
          },
        ];
        setSubscriptions(formattedSubscriptions);
      }
    }
  }, [selectedMissionDE]);

  // States
  const [compteur, setCompteur] = useState(230);
  const textAreaRef = useRef(null);

  // Bloque le nombre de caractères à 230 et met à jour le compteur
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let nouveauTexte = e.target.value;
    if (nouveauTexte.length > 230) {
      nouveauTexte = commentDE; // réinitialise à l'ancienne valeur
    }
    const nouveauCompteur = 230 - nouveauTexte.length;
    setCommentDE(nouveauTexte);
    setCompteur(nouveauCompteur);
  };

  // Call api pour recupérer des fournisseurs, clients et acteurs
  useEffect(() => {
    getSuppliersByDosRef(selectedFile)
      .then((response) => {
        const formattedSuppliers = response?.data.map((supplier: any) => ({
          label: supplier.FIC_TITRE_ETAT,
          value: supplier.FIC_REF,
        }));
        setSuppliers(formattedSuppliers);
      })
      .catch((error) => console.log(error));

    getCustomerByDosRef(selectedFile)
      .then((response) => {
        const formattedCustomers = response?.data.map((customer: any) => ({
          label: customer.FIC_TITRE_ETAT,
          value: customer.FIC_REF,
          cle: customer.FIC_CLE,
          id: customer.PFIC_ID,
        }));

        setCustomers(formattedCustomers);
      })
      .catch((error) => console.log(error));

    getAllUsersWithInactive()
      .then((res) => {
        setSelectedActorDE(id);
        const formattedFiles = res.data
          .filter((actor: any) => actor.actif === 1)
          .map((actor: any) => ({
            label: actor.firstname + " " + actor.lastname,
            value: actor.identifiant,
          }));
        setActors(
          formattedFiles.sort((a: any, b: any) =>
            a.label.localeCompare(b.label)
          )
        );
      })
      .catch((err) => console.log(err));
  }, [selectedFile]);

  /**
   * @author Succi Iris
   * @date 2023
   * @description Fonction qui permet de gérer le changement de l'origine
   */
  const handleOriginChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOrigin = event.target.value;
    setSelectedOriginDE(selectedOrigin);
    const selectedOriginObj = origins?.find(
      (origin) => origin.value.toString() === selectedOrigin
    );

    if (selectedOriginObj) {
      setLibelles(selectedOriginObj.libelles);
    } else {
      setLibelles([]);
    }
  };

  // Mise a jour de FIC_CLE et de PFIC_ID en fonction du client selectionné
  useEffect(() => {
    const selectedCustomerData = customers?.find((client) => {
      return client.value === selectedCustomer;
    });

    setFIC_CLE(selectedCustomerData?.cle);
    setPFIC_ID(selectedCustomerData?.id);
  }, [selectedCustomer]);

  // mise à jour des données du formulaire en mode édition
  useEffect(() => {
    if (Object.keys(updateData).length > 0 && modeFee === "edit") {
      if (missions.length > 0) {
        const selectedMission = missions?.find(
          (mission: any) => mission.value === updateData.MIS_REF
        );
        setSelectedMissionDE(selectedMission?.value || 0);
      }

      setSelectedActorDE(updateData?.ANA_OPERATEUR);

      if (suppliers.length > 0) {
        const selectedSupplier = suppliers?.find(
          (supplier: any) => supplier.value === updateData.FIC_REF
        );
        setSelectedSupplier(selectedSupplier?.value || 0);
      }

      if (customers.length > 0) {
        const selectedCustomer = customers?.find(
          (customer: any) => customer.value === updateData.FIC_REF
        );
        setSelectedCustomer(selectedCustomer?.value || 0);
      }

      if (origins.length > 0) {
        const selectedOrigin = origins?.find(
          (origin: any) => origin.value === updateData.AOR_REF
        );
        setSelectedOriginDE(selectedOrigin?.value || "");

        if (selectedOrigin) {
          setLibelles(selectedOrigin.libelles);
        }

        const selectedLibelle = libelles?.find(
          (libelle: any) => libelle.value === updateData.ANA_CODELIB
        );
        setSelectedLibelleDE(selectedLibelle?.value || "");
      }

      setCommentDE(updateData.ANA_NOTES);
      setSelectedSubscriptionDE(updateData.ABN_REF);
      setPiece(updateData.ANA_PIECE);
      setLibelleCpt(updateData.ANA_LIBELLE_CPT);
      setQuantityDE(updateData.ANA_QUANTITE);
      setSelectedTvaRateDE(updateData.TVA_CLE);
      setNsBlocked(updateData.ALI_PU_IN_NS);
      // ACHAT
      setUnitPriceBuy(updateData.ANA_ECOUTUNITAIRE);
      setHtBuy(updateData.ANA_ECOUTHT);
      setTvaBuy(updateData.ANA_ECOUTTVA);
      setTtcBuy(updateData.ANA_ECOUTTTC);
      setNsBuy(updateData.ANA_ECOUTNS);
      // VENTE
      setUnitPriceSell(updateData.ANA_EPRIXUNITAIRE);
      setHtSell(updateData.ANA_EMTHT);
      setTtcSell(updateData.ANA_EMTTTC);
      setNsSell(updateData.ANA_EMTNS);
      setTvaSell(updateData.ANA_EMTTVA);
    }
  }, [missions, origins, libelles, updateData, modeFee]);

  // Call api pour récupérer les tarifs en fonction de l'origine et du libellé
  useEffect(() => {
    const fetchTarifs = async () => {
      if (selectedLibelleDE !== "") {
        try {
          const response = await getTarifs(selectedOriginDE, selectedLibelleDE);
          const tarifs = response.data;
          setPrices(tarifs);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchTarifs();
  }, [selectedLibelleDE, selectedOriginDE, quantityDE]);

  useEffect(() => {
    if (selectedLibelleDE !== "" && modeFee === "create") {
      const selectedLibelle = libelles?.find(
        (libelle) => libelle.value === selectedLibelleDE
      );
      if (selectedLibelle) {
        setQuantityDE(selectedLibelle?.quantite);
        setUnitPriceSell(selectedLibelle?.ePrixUnitaireVente);
        setUnitPriceBuy(selectedLibelle?.ePrixUnitaire);
        setNsBlocked(selectedLibelle?.nsBlock);
      }
    }
  }, [selectedLibelleDE]);

  useEffect(() => {
    if (quantityDE > 0 && prices.length > 0) {
      // Trouve le prix correspondant exact si disponible
      let priceMatch = prices.find(
        (price) => quantityDE === price.ALIT_QUANTITE
      );

      // Si aucun prix exact trouvé, cherche la quantité inférieure la plus proche
      if (!priceMatch) {
        priceMatch = prices
          .filter((price) => price.ALIT_QUANTITE <= quantityDE)
          .reduce(
            (closest, price) =>
              price.ALIT_QUANTITE > closest.ALIT_QUANTITE ? price : closest,
            { ALIT_QUANTITE: 0, ALIT_EPRIX_UNITAIRE: 0 }
          );
      }
      setUnitPriceSell(priceMatch?.ALIT_EPRIX_UNITAIRE);
    }
  }, [quantityDE]);

  return (
    billableItem === "Debours externe" && (
      <div className="w-full flex flex-col justify-start items-center h-auto">
        <div className="w-full grid grid-cols-12 3xl:gap-5 gap-2 mt-5">
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Client:</label>
          </p>
          <select
            name="client"
            id="client"
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedCustomer}
            onChange={(event) => {
              setSelectedCustomer(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {customers?.map((client, i) => (
              <option key={i} value={client?.value}>
                {client?.label}
              </option>
            ))}
          </select>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Fournisseur:</label>
          </p>
          <select
            name="Fournisseur"
            id="Fournisseur"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedSupplier}
            onChange={(event) => {
              setSelectedSupplier(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {suppliers?.map((supplier, i) => (
              <option key={i} value={supplier?.value}>
                {supplier?.label}
              </option>
            ))}
          </select>
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Mission:</label>
          </p>
          <select
            name="mission"
            id="mission"
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedMissionDE}
            onChange={(event) => {
              setSelectedMissionDE(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {missions?.map((mission, i) => (
              <option key={i} value={mission?.value}>
                {mission?.label}
              </option>
            ))}
          </select>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Acteur:</label>
          </p>
          <select
            name="actor"
            id="actor"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedActorDE}
            onChange={(event) => {
              setSelectedActorDE(event.target.value);
            }}
          >
            <option value={""}></option>
            {actors?.map((actor, i) => (
              <option key={i} value={actor?.value}>
                {actor?.label}
              </option>
            ))}
          </select>
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Origine:</label>
          </p>
          <select
            name="origins"
            id="origins"
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedOriginDE}
            onChange={handleOriginChange}
            disabled={origins?.length === 1}
          >
            <option value={""}></option>
            {origins?.map((origin, i) => (
              <option key={i} value={origin?.value}>
                {origin?.label || ""}
              </option>
            ))}
          </select>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Libellé:</label>
          </p>
          <select
            name="libelle"
            id="libelle"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedLibelleDE}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedLibelleDE(selectedValue);
            }}
          >
            <option value={""}></option>
            {libelles?.map((libelle, i) => (
              <option key={i} value={libelle?.value}>
                {libelle?.label}
              </option>
            ))}
          </select>
          <p className="col-start-1 col-end-3">
            <label htmlFor="mission">Commentaire:</label>
          </p>
          <div className="col-start-3 col-end-7 mt-1 flex flex-col">
            <textarea
              name="Comm"
              id="Comm"
              value={commentDE}
              ref={textAreaRef}
              onChange={handleChange}
              className="w-full border-[1px] rounded border-gray-300 h-14 py-1 z-20 "
            />
            <div className="w-full flex justify-end items-end pt-1 pr-2">
              <p className="text-gray-400 text-xs">
                Il vous reste {compteur} caractère(s)
              </p>
            </div>
          </div>
          <p className="col-start-7 col-end-9">
            <label htmlFor="mission">Abonnement:</label>
          </p>
          <select
            name="abonnement"
            id="abonnement"
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
            value={selectedSubscriptionDE}
            disabled={subscriptions.length === 0}
            onChange={(event) => {
              setSelectedSubscriptionDE(Number(event.target.value));
            }}
          >
            <option value={""}></option>
            {subscriptions?.map((subscription, i) => (
              <option key={i} value={subscription?.value}>
                {subscription?.label}
              </option>
            ))}
          </select>

          <hr className="col-start-3 col-end-12" />
          <div className="col-start-1 col-end-13">
            <AmountsForDE />
          </div>
          {/* <div className="col-start-1 col-end-4 flex justify-start items-center">
            <input
              type="checkbox"
              name="ne pas facturer"
              id="ne pas facturer"
              className="mr-4"
            />
            <p className="text-sm">Ne pas facturer</p>
          </div>
          <div className="col-start-4 col-end-7 flex justify-start items-center">
            <input type="checkbox" name="réglé" id="réglé" className="mr-4" />
            <p className="text-sm">Réglé</p>
          </div> */}
          {/* <div className="col-start-7 col-end-13 flex justify-start items-center">
            <input
              type="checkbox"
              name="provision"
              id="provision"
              className="mr-4"
            />
            <p className="text-sm">Demande de provision</p>
          </div> */}

          <hr className="col-start-3 col-end-12" />

          <p className="col-start-1 col-end-3">Libellé cpt</p>
          <input
            type="text"
            name="libelle cpt"
            id="libelle cpt"
            value={libelleCpt}
            onChange={(event) => setLibelleCpt(event.target.value)}
            className="col-start-3 col-end-7 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          />
          <p className="col-start-7 col-end-9">Pièce</p>
          <input
            type="text"
            name="piece"
            id="piece"
            value={piece}
            onChange={(event) => setPiece(event.target.value)}
            className="col-start-9 col-end-13 w-full border-[1px] rounded border-gray-300 h-9 py-1 z-20 "
          />
        </div>
      </div>
    )
  );
};

export default CreateDeboursExt;
