import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import chevron from "../../../assets/icons/chevron-right.svg";
import { useEffect } from "react";
import { useCreateFeesOrInvoices } from "../../../store/Invoice/buttonsStore";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { printPlugin } from "@react-pdf-viewer/print";
import "@react-pdf-viewer/print/lib/styles/index.css";
import printAsset from "../../../assets/icons/printer.svg";
import {
  RenderCurrentPageLabelProps,
  RenderGoToPageProps,
  pageNavigationPlugin,
} from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import chevronUp from "../../../assets/icons/chevron-upThin.svg";
import chevronDown from "../../../assets/icons/chevron-downThin.svg";
import download from "../../../assets/icons/downloadBlack.svg";
import { RenderDownloadProps, getFilePlugin } from "@react-pdf-viewer/get-file";
import { getPdf } from "../../../utils/Invoice/invoicing";
import { useInvoiceStore } from "../../../store/Invoice/invoice";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useLoadingStore } from "../../../store/utils/loading";
import { useTabsStore } from "../../../store/Invoice/tabsStore";
import { useSlip } from "../../../store/Invoice/createSlip";

/**
 * @component
 * @name VisuPdfModal
 * @author Succi Iris
 * @name VisuPdfModal
 * @description Composant qui permet de visualiser le PDF
 */
const VisuPdfModal = () => {
  const {
    animationClass,
    setAnimationClass,
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    displayDivVisuPdf,
    setDisplayDivVisuPdf,
  } = useCreateInvoiceStore();
  const { setSelectedBankSlip } = useSlip();
  const { visuPdf, setVisuPdf } = useCreateFeesOrInvoices();
  const { isLoading, setIsLoading } = useLoadingStore();
  const { documentUrl, setDocumentUrl } = useInvoiceStore();
  const { choiceTab } = useTabsStore();

  useEffect(() => {
    if (visuPdf === true) {
      setDisplayDivVisuPdf(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDivVisuPdf(false);
      }, 300);
    }
  }, [visuPdf]);

  useEffect(() => {
    setIsLoading(true);
    if (choiceTab !== "Bordereau") {
      getPdf(selectedIdsInvoices[0])
        .then((res) => {
          // Créer une URL à partir du blob
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [visuPdf]);

  const printPluginInstance = printPlugin();
  const { print } = printPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToFirstPage, GoToNextPage, CurrentPageLabel } =
    pageNavigationPluginInstance;

  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  return (
    <>
      {displayDivVisuPdf ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setSelectedIdsInvoices((_prev) => []);
                setVisuPdf(false);
                setDisplayDivVisuPdf(false);
                setSelectedBankSlip([]);
                setDocumentUrl("");
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-center items-start w-[80%] h-full m-auto mt-4">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
              <div className="w-full flex justify-between items-center h-10 bg-[#cecece] rounded-sm">
                <div className="ml-4 pt-2">
                  <GoToFirstPage>
                    {(props: RenderGoToPageProps) => (
                      <button onClick={props.onClick} className="mr-4">
                        <img src={chevronUp} alt="chevron" />
                      </button>
                    )}
                  </GoToFirstPage>
                  <GoToNextPage>
                    {(props: RenderGoToPageProps) => (
                      <button
                        // It will be disabled if we are already at the last page
                        disabled={props.isDisabled}
                        onClick={props.onClick}
                        className="mr-4"
                      >
                        <img src={chevronDown} alt="chevron" />
                      </button>
                    )}
                  </GoToNextPage>
                </div>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <span className="font-light text-sm">{`${
                      props.currentPage + 1
                    } of ${props.numberOfPages}`}</span>
                  )}
                </CurrentPageLabel>
                <div className="mr-4">
                  <button onClick={print} className="pl-2 my-2 mr-4">
                    <img src={printAsset} alt="imprimer" />
                  </button>
                  <Download>
                    {(props: RenderDownloadProps) => (
                      <button onClick={props.onClick}>
                        <img src={download} alt="Télécharger" />
                      </button>
                    )}
                  </Download>
                </div>
              </div>
              {isLoading ? (
                <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
                  <CircleLoarder />
                </div>
              ) : (
                documentUrl !== "" && (
                  <Viewer
                    fileUrl={documentUrl}
                    plugins={[
                      printPluginInstance,
                      pageNavigationPluginInstance,
                      getFilePluginInstance,
                    ]}
                    withCredentials={true}
                  />
                )
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VisuPdfModal;
