import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDateStore } from "../../../store/TimeManagement/dateStore";
import { useSlip } from "../../../store/Invoice/createSlip";

interface DatepickerProps {
  startDate?: any;
  setStartDate?: (date: any) => void;
}

/**
 * @component
 * @name Datepicker
 * @author Iris Succi
 * @date 2023
 * @description Composant Datepicker pour choisir une date
 *
 */

const Datepicker: React.FC<DatepickerProps> = ({ startDate, setStartDate }) => {
  // Si startDate et setStartDate ne sont pas fournis en tant que props, utilise ceux du store global
  const storeStartDate = useDateStore((state) => state.startDate);
  const storeSetStartDate = useDateStore((state) => state.setStartDate);

  const selectedDate = startDate ?? storeStartDate;
  const changeDate = setStartDate ?? storeSetStartDate;

  const { slipFormData } = useSlip();

  return (
    <div className="flex flex-col justify-start items-start mb-2 z-50">
      <DatePicker
        selected={selectedDate}
        onChange={(date: any) => changeDate(date)}
        className="border-[1px] rounded border-gray-300 lg:h-9 h-6 p-1 w-32 sm:w-40 z-50 font-thin"
        dateFormat={"dd/MM/yyyy"}
        /* Pour les bordereaux d ecritures de banque */
        disabled={slipFormData?.PFIXD === 1 && slipFormData?.PFIXDNB > 0}
      />
    </div>
  );
};

export default Datepicker;
