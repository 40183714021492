import { create } from 'zustand';

type utilsCrm = {
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;

    documentsList: any[];
    setDocumentsList: (documentsList: any[]) => void;

    missions : any[];
    setMissions : (missions : any[]) => void;

    resetUtilsCrm: () => void;
}

export const useUtilsCrmStore = create<utilsCrm>((set) => ({ 
    choiceTab: "",
    setChoiceTab: (choiceTab) => set({ choiceTab }),

    documentsList: [],
    setDocumentsList: (documentsList) => set({ documentsList }),

    missions : [],
    setMissions : (missions) => set({ missions }),

    resetUtilsCrm: () => set({ choiceTab: "", documentsList: [], missions: [] })
}));