import { useEffect, useRef, useState } from "react";
import Header from "../components/Layout/Header";
import Navbar from "../components/Layout/Navbar";
import Footer from "../components/Layout/Footer";
import WeekOrDayButtons from "../components/TimeManager/buttons/WeekOrDayButtons";
import WeekViews from "../components/TimeManager/tables/WeekViews";
import useModal from "../hooks/useModal";
import EditView from "../components/TimeManager/tables/EditView";
import AddLine from "../components/TimeManager/buttons/AddLine";
import {
  getByInterval,
  getByWeek,
  getDaysOffWeek,
  getTimePotentialByWeek,
  getWeekSummary,
} from "../utils/TimeManager/time";
import { dateFormatted, getMonday } from "../utils/TimeManager/timeUtils";
import { useUserContext } from "../context/UserContext";
import ListView from "../components/TimeManager/tables/ListView";
import { Toaster } from "react-hot-toast";
import { useViewStore } from "../store/TimeManagement/viewStore";
import { useDateStore } from "../store/TimeManagement/dateStore";
import { useLoadingStore } from "../store/utils/loading";
import { useDataStore } from "../store/TimeManagement/dataStore";
import { useListStore } from "../store/TimeManagement/listStore";
import { errorFetch } from "../utils/toaster";
/**
 * @page
 * @name TimeManagement
 * @description
 * TimeManagement est la page principale de l'application pour la gestion des temps. Elle permet de visualiser, éditer et valider les temps de travail.
 * @author Iris Succi
 * @date 2023
 */
const TimeManagement = () => {
  // Context
  const { id } = useUserContext();

  // Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Store
  const { startDate } = useDateStore();
  const { view, editMode } = useViewStore();
  const { setIsLoading } = useLoadingStore();
  const {
    setDataMonth,
    setDataWeek,
    setDataDaysOffWeek,
    setDataWeekSummary,
    setTimePotential,
  } = useDataStore();
  const { setDataFilterList, selectedItems, setSelectedTimeForValidated } =
    useListStore();

  // Ref for tableWeek
  const tableRefForWeek = useRef();

  // State
  const [user, setUser] = useState<string>(id);
  // Pour ajouter une ligne dans la vue d'édition
  const [validatedSendTime, setValidatedSendTime] = useState(false);
  const [renderData, setRenderData] = useState<boolean>(false);

  // Sert à rafraichir la page quand l'utilisateur change
  useEffect(() => {
    setUser(id);
  }, [id]);

  useEffect(() => {
    setSelectedTimeForValidated([]);
  }, [view]);

  // Appel de l'api quand l'utilisateur change ou la date change ou la vue change
  useEffect(() => {
    if (view === "week") {
      const mondayOfCurrentWeek = getMonday(startDate);
      getByWeek(user, dateFormatted(mondayOfCurrentWeek))
        .then((res) => {
          setDataWeek(res.data);
          getDaysOffWeek(user, dateFormatted(mondayOfCurrentWeek))
            .then((res) => {
              getTimePotentialByWeek(user, dateFormatted(mondayOfCurrentWeek))
                .then((res) => setTimePotential(res.data))
                .catch((err) => console.log(err));
              setDataDaysOffWeek(res.data);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }

    if (view === "list") {
      setIsLoading(true);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() - 8);
      getByInterval(user, dateFormatted(endDate), dateFormatted(startDate))
        .then((res) => {
          setDataMonth(res.data);
          setDataFilterList(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }

    if (view === "edit") {
      setIsLoading(true);
      const mondayOfCurrentWeek = getMonday(startDate);
      getWeekSummary(user, dateFormatted(mondayOfCurrentWeek))
        .then((res) => {
          setDataWeekSummary(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }
  }, [startDate, user, selectedItems, validatedSendTime, renderData, view, id]);

  return (
    <div className="w-screen h-screen lg:overflow-hidden overflow-x-scroll">
      <Toaster position="top-center" reverseOrder />
      <Header
        openModal={openModal}
        closeModal={closeModal}
        user={user}
        setUser={setUser}
        setValidatedSendTime={setValidatedSendTime}
        setRenderData={setRenderData}
        tableRefForWeek={tableRefForWeek}
      />
      <div className="flex">
        <div className="h-auto">
          <Navbar />
        </div>

        {/* MODAL */}
        {isModalOpen ? modalContent : null}

        <div className="flex flex-col w-screen">
          <div className="flex justify-between mt-5 ml-7 ">
            {editMode && view === "edit" ? (
              <AddLine />
            ) : (
              <WeekOrDayButtons
                openModal={openModal}
                closeModal={closeModal}
                setIsLoading={setIsLoading}
              />
            )}
          </div>

          {/* TABLES */}
          <div className="flex justify-center h-[80vh] mr-1">
            {view === "list" ? (
              <ListView setRenderData={setRenderData} />
            ) : editMode && view !== "week" ? (
              <EditView
                openModal={openModal}
                closeModal={closeModal}
                user={user}
                setRenderData={setRenderData}
                renderData={false}
              />
            ) : (
              <WeekViews tableRefForWeek={tableRefForWeek} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TimeManagement;
