import { useEffect, useState } from "react";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { getParticipantsCategories } from "../../../../utils/participants";
import ParticipantsTree from "./ParticipantsTree";
import CreateIntervenant from "../../buttons/CreateIntervenant";

/**
 * @component
 * @name ExplorerParticipantsCategories
 * @description A component that displays the participants categories for a given dossier.
 * @author Iris Succi
 * @date 2023
 */
const ExplorerParticipantsCategories: React.FC<any> = () => {
  const {
    PDOS_ID,
    participantsCategories,
    setParticipantsCategories,
    clientFileName,
  } = useExplorer();

  // L'état pour garder la trace de l'onglet ouvert
  const [openParticipanCategorytId, setParticipantCategoryId] = useState(null);

  const handleToggleParticipant = (participantCategoryId: any) => {
    if (openParticipanCategorytId === participantCategoryId) {
      // Si le même onglet est cliqué, fermez-le
      setParticipantCategoryId(null);
    } else {
      // Sinon, ouvrez le nouvel onglet
      setParticipantCategoryId(participantCategoryId);
    }
  };

  useEffect(() => {
    getParticipantsCategories(PDOS_ID)
      .then((res) => setParticipantsCategories(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex flex-col w-[98%] h-full overflow-y-scroll border-[1px] rounded-md mt-2 pt-2 bg-bc-lightGrey items-center">
      {clientFileName !== "" ? clientFileName : null}
      <hr className="my-2 w-6/12 m-auto" />
      <CreateIntervenant />
      {participantsCategories?.map((category) => (
        <ParticipantsTree
          key={category?.PENT_ID}
          category={category}
          isOpen={openParticipanCategorytId === category?.PENT_ID}
          onToggle={() => handleToggleParticipant(category?.PENT_ID)}
        />
      ))}
    </div>
  );
};

export default ExplorerParticipantsCategories;
