import { useEffect } from "react";
import { getFeesLinkedToProforma } from "../../../../utils/Invoice/invoicing";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useCreateProformaStore } from "../../../../store/Invoice/createProforma";
import { dateFormattedFr } from "../../../../utils/TimeManager/timeUtils";
import { getFeesBillables } from "../../../../utils/Invoice/fees";

/**
 * @author Succi Iris
 * @date 2023
 * @function TableUpdateProforma
 * @name TableUpdateProforma
 * @description Tableau qui affiche les frais à lier ou délier à la proforma
 */

const TableUpdateProforma = () => {
  const { selectedIdsInvoices } = useCreateInvoiceStore();
  const {
    feesForUpdateToLinkProforma,
    setFeesForUpdateToLinkProforma,
    feesForUpdateToUnlinkProforma,
    setFeesForUpdateToUnlinkProforma,
    checkedFees,
    setCheckedFees,
  } = useCreateProformaStore();

  /**
   * @function fetchAndSetFees
   * @name fetchAndSetFees
   * @description Fonction qui permet de récupérer les frais liés à la proforma
   * @param {array<number>} selectedIdsInvoices - An array of the IDs of the selected invoices.
   */
  useEffect(() => {
    getFeesLinkedToProforma(selectedIdsInvoices[0])
      .then((res) => {
        setFeesForUpdateToLinkProforma(res.data);

        const targetDosRef = res.data[0]?.DOS_REF;

        if (targetDosRef) {
          getFeesBillables()
            .then((allFees) => {
              const filteredFees = allFees.data.filter(
                (fee: any) => fee.DOS_REF === targetDosRef
              );
              setFeesForUpdateToUnlinkProforma(filteredFees);
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }, [selectedIdsInvoices]);

  useEffect(() => {
    const initialChecked = feesForUpdateToLinkProforma.map(
      (fee) => fee.ANA_REF
    );
    setCheckedFees(initialChecked);
  }, [feesForUpdateToLinkProforma]);

  const handleCheckboxChange = (feeId: any) => {
    if (checkedFees.includes(feeId)) {
      setCheckedFees(checkedFees.filter((id) => id !== feeId));
    } else {
      setCheckedFees([...checkedFees, feeId]);
    }
  };

  return (
    <table className="w-[100%] bg-white border-[1px] rounded-md border-gray-200 h-full overflow-y-scroll flex justify-start">
      <tbody className="w-[100%]">
        {feesForUpdateToLinkProforma?.map((fee) => (
          <tr className="w-[100%] border-b-[1px] h-10 flex justify-start items-center p-2 text-xs">
            <td className="w-[5%]">
              <input
                type="checkbox"
                className="ml-2"
                name="ids"
                id="ids"
                checked={checkedFees.includes(fee.ANA_REF)}
                onChange={() => handleCheckboxChange(fee.ANA_REF)}
              />
            </td>
            <td className="w-[30%]">{fee.FIC_TITRE_CLIENT}</td>
            <td className="w-[40%]">{fee.MIS_LIBELLE}</td>
            <td className="w-[10%]">
              {dateFormattedFr(fee.ANA_DATEREALISATION)}
            </td>
            <td className="w-[10%]">{fee.ANA_EMTTTC} € TTC</td>
          </tr>
        ))}
        {feesForUpdateToUnlinkProforma?.map((fee) => (
          <tr className="w-full border-b-[1px] h-10  flex justify-start items-center p-2 text-xs">
            <td className="w-[5%]">
              <input
                type="checkbox"
                name="ids"
                className="ml-2"
                id="ids"
                checked={checkedFees.includes(fee.ANA_REF)}
                onChange={() => handleCheckboxChange(fee.ANA_REF)}
              />
            </td>
            <td className="w-[30%]">{fee.FIC_TITRE_CLIENT}</td>
            <td className="w-[40%]">{fee.MIS_LIBELLE}</td>
            <td className="w-[10%]">
              {dateFormattedFr(fee.ANA_DATEREALISATION)}
            </td>
            <td className="w-[10%]">{fee.ANA_EMTTTC} € TTC</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableUpdateProforma;
