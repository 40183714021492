import { useTranslation } from "react-i18next";
import { useDateStore } from "../../../store/TimeManagement/dateStore";

/**
 * @component
 * @name TodayButton
 * @author Iris Succi
 * @date 2023
 * @description Bouton pour revenir à la date du jour
 */

const TodayButton = () => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { setStartDate } = useDateStore();

  return (
    <button
      className="w-auto lg:h-9 h-6 bg-bc-orange rounded hidden lg:flex justify-center items-center mx-2 text-white px-2 shadow-md hover:bg-bc-lightOrange transition-colors duration-200"
      onClick={() => setStartDate(new Date())}
    >
      {t("Aujourd'hui")}
    </button>
  );
};

export default TodayButton;
