import { useTranslation } from "react-i18next";
import Search from "../../assets/icons/search.svg";
import { useSearchBarStore } from "../../store/utils/searchBar";

/**
 * @component
 * @name SearchBar
 * @author Iris Succi
 * @date 2023
 * @description Barre de recherche
 */

const SearchBar = () => {
  //Translation
  const { t } = useTranslation();

  //Store
  const { search, setSearch } = useSearchBarStore();

  return (
    <div className="relative w-80 flex">
      <input
        type="text"
        className="w-full border-[1px] rounded-md h-8 p-2 ml-2"
        placeholder={t("Recherche un dossier")}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <img src={Search} alt="search" className="absolute right-3 top-2" />
    </div>
  );
};

export default SearchBar;
