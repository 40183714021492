import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import { useCreateProformaStore } from "../../../../store/Invoice/createProforma";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import { useLoadingStore } from "../../../../store/utils/loading";
import { validateProforma } from "../../../../utils/Invoice/invoicing";
import { postCheckPrepayment } from "../../../../utils/Invoice/prepayments";
import {
  errorSendNewTime,
  successValidateAndPoint,
} from "../../../../utils/toaster";

/**
 * @component
 * @name PointOutAcompte
 * @author Succi Iris
 * @date 2023
 * @description Pointer un acompte
 * @returns {JSX.Element} - The rendered component.
 */
const PointOutAcompte = () => {
  const {
    displayPointOutAcompteQuestion,
    setDisplayPointOutAcompteQuestion,
    setSendData,
    setChoiceTab,
  } = useTabsStore();
  const {
    selectedIdsInvoices,
    setSelectedIdsInvoices,
    selectedIdPrepayment,
    setDisplayPointOutAcompteModal,
    setDisplayDiv,
    setDisplayDivVisuPdf,
  } = useCreateInvoiceStore();
  const { setModeProforma } = useCreateProformaStore();
  const { setVisuPdf, setCreateProformat } = useCreateFeesOrInvoices();
  const { setIsLoading } = useLoadingStore();

  /**
   * @async
   * @function handleValidateProformaWithPoint
   * @description Fonction qui permet de valider la proforma avec pointage
   * @date 2024
   * @author Iris Succi
   */
  const handleValidateProformaWithPoint = async () => {
    try {
      setIsLoading(true);
      setDisplayPointOutAcompteQuestion(false);
      const response = await validateProforma(String(selectedIdsInvoices));
      if (response.status === 200) {
        setDisplayPointOutAcompteModal(false);
        // Si le pointage de l'acompte est réussi, on vérifie si la facture
        postCheckPrepayment(selectedIdPrepayment)
          .then(() => {
            setSelectedIdsInvoices((_prev) => []);
            setSendData((_prev) => true);
            setChoiceTab("Facture");
            successValidateAndPoint();
            setIsLoading(false);
          })
          .catch((err: any) => console.log(err));
      } else {
        console.error("Réponse non réussie avec le statut:", response.status);
      }
    } catch (error) {
      console.error("Erreur:", error);
      errorSendNewTime();
    }
  };

  /**
   * Handles validating the proforma invoice without pointing out the acompte
   *
   * @description
   * This function validates the proforma invoice with the selected invoices,
   * does not point out the acompte, and updates the relevant stores.
   *
   * @throws Error if the `validateProforma` request fails.
   */
  /* const handleValidateProformaWithoutPoint = async () => {
    try {
      const response = await validateProforma(String(selectedIdsInvoices));
      setSelectedIdsInvoices((_prev) => []);
      setSendData((_prev) => true);
      console.log("Succès:", response);
    } catch (error) {
      console.error("Erreur:", error);
      errorSendNewTime();
    }
  }; */

  return (
    displayPointOutAcompteQuestion && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[45%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t w-full">
                <h3 className="text-base md:text-3xl font-semibold text-center">
                  Pointer un acompte ?
                </h3>
              </div>
              {/*body*/}
              <div className="text-sm md:text-base text-center p-5">
                Votre pré-facture contient un acompte, souhaitez-vous le
                pointer?
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setDisplayPointOutAcompteQuestion(false);
                    setSendData((_prev) => true);
                  }}
                >
                  Annuler
                </button>
                <button
                  className="text-bc-orange background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setDisplayDivVisuPdf(true);
                    setVisuPdf(true);
                    setDisplayPointOutAcompteQuestion(false);
                  }}
                >
                  Visualiser
                </button>
                <button
                  className="text-bc-orange background-transparent font-bold uppercase px-4 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setDisplayPointOutAcompteQuestion(false);
                    setCreateProformat(true);
                    setDisplayDiv(true);
                    setModeProforma("edit");
                  }}
                >
                  Modifier
                </button>
                {/* <button
                  className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setDisplayPointOutAcompteQuestion(false);
                    handleValidateProformaWithoutPoint();
                    setChoiceTab("Proformat");
                  }}
                >
                  Valider sans pointer
                </button> */}
                <button
                  className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-4 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleValidateProformaWithPoint();
                  }}
                >
                  Valider et pointer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default PointOutAcompte;
