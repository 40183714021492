import { create } from 'zustand';
import { SelectOptionDossier, SelectOptionsMission } from '../../interface/selects';

interface DayTime {
    time: string;
    date: any; 
  }

interface MyState {
  fileOptions: SelectOptionDossier[],
  setFileOptions: (options: SelectOptionDossier[]) => void,
  
  missions: SelectOptionsMission[],
  setMissions: (missions: SelectOptionsMission[]) => void,
  
  origins: any[],
  setOrigins: (origins: any[]) => void,
  
  libelles: any[],
  setLibelles: (libelles: any[]) => void,
  
  tauxHoraires: any,
  setTauxHoraires: (tauxHoraires: number[] | string) => void,

  dayTimes: { [key: number]: DayTime };
  setDayTimes: (value: React.SetStateAction<{ [key: number]: DayTime }>) => void;

  selectedFile: number,
  setSelectedFile: (file: number) => void,
  
  selectedMission: number,
  setSelectedMission: (mission: number) => void,
  
  selectedOrigin: string,
  setSelectedOrigin: (origin: string) => void,
  
  selectedLibelle: string,
  setSelectedLibelle: (libelle: string) => void,
  
  selectedTauxHoraire: number,
  setSelectedTauxHoraire: (tauxHoraire: number) => void,
  
  updateData: boolean,
  setUpdateData: (updateData: boolean) => void,
  
  commentsForSend: Record<string, any>,
  setCommentsForSend: (comments: Record<string, any>) => void

  timeId: number,
  setTimeId: (timeId: number) => void,
}

export const editStore = create<MyState>((set) => ({
    fileOptions: [],
    missions: [],
    origins: [],
    libelles: [],
    tauxHoraires: [],
    dayTimes: {},
    selectedFile: 0,
    selectedMission: 0,
    selectedOrigin: "",
    selectedLibelle: "",
    selectedTauxHoraire: 0,
    updateData: false,
    commentsForSend: {},
    timeId: 0,
    setFileOptions: (options) => set({ fileOptions: options }),
    setMissions: (missions) => set({ missions }),
    setOrigins: (origins) => set({ origins }),
    setLibelles: (libelles) => set({ libelles }),
    setTauxHoraires: (tauxHoraires) => set({ tauxHoraires }),
    setDayTimes: (value) => set((prevState) => ({ 
        dayTimes: typeof value === 'function' ? value(prevState.dayTimes) : value 
      })),    setSelectedFile: (file) => set({ selectedFile: file }),
    setSelectedMission: (mission) => set({ selectedMission: mission }),
    setSelectedOrigin: (origin) => set({ selectedOrigin: origin }),
    setSelectedLibelle: (libelle) => set({ selectedLibelle: libelle }),
    setSelectedTauxHoraire: (tauxHoraire) => set({ selectedTauxHoraire: tauxHoraire }),
    setUpdateData: (updateData) => set({ updateData }),
    setCommentsForSend: (comments) => set({ commentsForSend: comments }),
    setTimeId: (timeId) => set({ timeId }),
  }));