import { useEffect, useRef } from "react";
import { dataInvoicesFeelsStore } from "../../../store/Invoice/dataInvoicesFeelsStore";
import { useSearchBarStore } from "../../../store/utils/searchBar";
import { usePagination } from "../../../hooks/usePagination";
import { useLoadingStore } from "../../../store/utils/loading";
import CircleLoarder from "../../TimeManager/loader/CircleLoarder";
import { useCreateInvoiceStore } from "../../../store/Invoice/createInvoice";
import { CreateCredit } from "../modal/Create/CreateCredit";
import {
  columnMappings,
  orderedColumnsInvoice,
} from "../../../data/dataInvoice";
import { useInvoiceStore } from "../../../store/Invoice/invoice";
import { dateFormattedFr } from "../../../utils/TimeManager/timeUtils";
import VisuPdfModal from "../modal/VisuPdfModal";
import plane from "../../../assets/icons/plane.png";
import { formatNumber } from "../../../utils/functionService";
import CreateSchedule from "../modal/Create/CreateSchedule";
import { useCreateSchedule } from "../../../store/Invoice/schedule";

/**
 * @Component InvoicesTable
 * @author Succi Iris
 * @date 2023
 * @description Ce composant affiche un tableau de factures, permettant aux utilisateurs de les sélectionner et de les valider. Il gère également la pagination et la fonctionnalité de recherche.
 * @returns {JSX.Element} - The rendered component
 */
export const InvoicesTable = () => {
  const { invoices, invoicesFilter, setInvoicesFilter } =
    dataInvoicesFeelsStore();
  const { search } = useSearchBarStore();
  const { isLoading } = useLoadingStore();
  const { selectedItems, currentItems } = useInvoiceStore();
  const {
    displayDiv,
    selectedIdsInvoices,
    displayDivSchedule,
    setSelectedIdsInvoices,
    setSelectedDosRef,
    displayDivVisuPdf,
  } = useCreateInvoiceStore();

  const {
    currentPage,
    setCurrentPage,
    startPage,
    setStartPage,
    endPage,
    setEndPage,
    filteredData,
    totalPageCount,
  } = usePagination(invoicesFilter, 30);

  const { setSolde, setANA_REF } = useCreateSchedule();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (displayDiv && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [displayDiv]);

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} e - The event object triggered by the checkbox click
   * @param {number} itemId - The id of invoice item associated with the checkbox
   * @param {number} dosRef - The dos reference of invoice item associated with the checkbox
   * @description
   * This function handles the change of an individual checkbox in the invoice. It checks if the checkbox is checked, and if so, it adds the corresponding item ID to the `selectedIdsInvoices` array. It also sets the `selectedDosRef` to the dos reference associated with the item. If the checkbox is unchecked, it removes the item ID from the `selectedIdsInvoices` array and sets the `selectedDosRef` to `null` if the item ID is no longer present in the array.
   */
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    itemId: number,
    dosRef: number,
    solde: number,
    ANA_REF: number
  ) => {
    if (e.target.checked) {
      setSelectedIdsInvoices((prev) => [...prev, itemId]);
      setSelectedDosRef(dosRef);
      setSolde(solde.toString());
      setANA_REF(ANA_REF);
    } else {
      setSelectedIdsInvoices((prev) => prev.filter((id) => id !== itemId));
      if (!selectedIdsInvoices.includes(itemId)) {
        setSelectedDosRef(null);
      }
    }
  };

  // This is for display data with search bar
  useEffect(() => {
    let data = invoices?.length > 0 ? [...invoices] : [...invoicesFilter];
    if (search !== "") {
      data = data.filter(
        (item) =>
          item["DOS_TITRE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["MIS_LIBELLE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["AOR_CODE"]?.toLowerCase().includes(search.toLowerCase()) ||
          item["ANA_CODELIB"]?.toLowerCase().includes(search.toLowerCase()) ||
          dateFormattedFr(item["ANA_DATEREALISATION"])
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item["ANA_EMTTTC"]
            ?.toString()
            .toLowerCase()
            .includes(search.toLowerCase())
      );
    }

    // Trie par date du plus récent au plus ancien (ANA_DATEREALISATION)
    data.sort((a, b) => {
      const dateA = new Date(a.ANA_DATEREALISATION).getTime();
      const dateB = new Date(b.ANA_DATEREALISATION).getTime();
      return dateB - dateA; // Trie du plus récent au plus ancien
    });

    setInvoicesFilter(data);
  }, [search, invoices, currentPage]);

  const sortedSelectedItems = orderedColumnsInvoice.filter((column) =>
    selectedItems.includes(column)
  );

  return (
    <>
      <div className="relative border-[1px] rounded-b-md w-full h-full overflow-x-hidden">
        {isLoading ? (
          <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
            <CircleLoarder />
          </div>
        ) : invoices.length > 0 ? (
          <div
            ref={scrollDivRef}
            className="border-[1px] rounded-b-md w-full h-full overflow-y-scroll"
          >
            <table className="w-full">
              <thead className="bg-[#cecece] border-b-[1px] sticky top-0  ">
                <tr>
                  <th></th>
                  {sortedSelectedItems.map((item, index) => (
                    <th
                      key={index}
                      className={`p-2 text-left font-normal border-r-[1px]`}
                    >
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {filteredData.map((item: any, index: any) => (
                  <tr
                    key={index}
                    className={`h-10 text-sm border-r-[1px] ${
                      index % 2 === 0 ? "bg-grey-Array" : "bg-white"
                    }`}
                    style={
                      index === currentItems.length - 1
                        ? { borderBottom: "1px solid #cecece" }
                        : {}
                    }
                  >
                    <td
                      className="flex justify-start items-center w-full"
                      style={{ width: "15%" }}
                    >
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        value={item.id}
                        checked={selectedIdsInvoices.includes(item.ANA_REF)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            item.ANA_REF,
                            item.DOS_REF,
                            item.ANA_ESOLDE,
                            item.ANA_REF
                          )
                        }
                        className="block mt-3 ml-2 mr-1 "
                      />
                      {item?.isSent ? (
                        <img src={plane} alt="plane" className="w-5 h-5 mt-2" />
                      ) : (
                        ""
                      )}
                    </td>
                    {sortedSelectedItems.map((selectedItem, selectedIndex) => (
                      <td
                        key={selectedIndex}
                        className="p-2 border-b-[1px] border-r-[1px]"
                      >
                        {selectedItem === "Date création"
                          ? dateFormattedFr(item[columnMappings[selectedItem]])
                          : selectedItem === "Date facturation"
                          ? dateFormattedFr(item[columnMappings[selectedItem]])
                          : selectedItem === "HT"
                          ? formatNumber(item[columnMappings[selectedItem]])
                          : selectedItem === "TTC"
                          ? formatNumber(item[columnMappings[selectedItem]])
                          : item[columnMappings[selectedItem]]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center w-full mt-5 italic">Aucune facture</p>
        )}
        {displayDiv ? <CreateCredit /> : null}
        {displayDivVisuPdf ? <VisuPdfModal /> : null}
        {displayDivSchedule ? <CreateSchedule /> : null}
      </div>
      <>
        {totalPageCount > 1 ? (
          <div className="flex justify-center items-center w-full m-auto pt-2">
            <button
              disabled={currentPage <= 10}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage - 10);
                setStartPage((prev) => Math.max(prev - 10, 1));
                setEndPage((prev) => prev - 10);
              }}
            >
              Précédent
            </button>

            {startPage > 1 && <span>...</span>}

            {Array(totalPageCount)
              .fill(null)
              .slice(startPage - 1, endPage)
              .map((_, index) => (
                <button
                  key={index + startPage}
                  className={`hover:bg-bc-lightOrange ${
                    currentPage === index + startPage ? "bg-bc-orange" : ""
                  } border-[1px] rounded-md border-bc-grey h-auto w-8 m-1`}
                  onClick={() => setCurrentPage(index + startPage)}
                >
                  {index + startPage}
                </button>
              ))}

            {endPage < totalPageCount && <span>...</span>}

            <button
              disabled={currentPage === totalPageCount}
              className="hover:bg-bc-lightOrange border-[1px] rounded-md border-bc-grey h-auto w-auto px-1 m-1"
              onClick={() => {
                setCurrentPage(currentPage + 10);
                setStartPage((prev) => prev + 10);
                setEndPage((prev) => Math.min(prev + 10, totalPageCount));
              }}
            >
              Suivant
            </button>
          </div>
        ) : null}
      </>
    </>
  );
};
