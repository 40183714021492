import axios from "axios";
import { axiosWithToken } from "../axiosService";
import { dateFormattedFr } from "../TimeManager/timeUtils";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / delete slips
 */

export const getSlipsBankRemittanceArray = () => {
    try {
        const response = axiosWithToken().get(`api/slips/bankremittance/existant/form`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsBankTransfertArray = () => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/existant/form`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsUnpayedArray = () => {
    try {
        const response = axiosWithToken().get(`api/slips/unpayed/existant/form`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipBankRemittance = (date: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/bankremittance/form?collectionDate=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipDocumentBankRemittance = (ABA_REFs: any,date: any, choice: string) => {
    try {
        const response = axiosWithToken().get(`api/slips/bankremittance?ABA_REFs=${ABA_REFs}&collectionDate=${date}&choice=${choice}&validation=false`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipDocumentBankRemittanceValidate = (ABA_REFs: any,date: any, choice: string) => {
    try {
        const response = axiosWithToken().get(`api/slips/bankremittance?ABA_REFs=${ABA_REFs}&collectionDate=${date}&choice=${choice}&validation=true`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsUnpayed = (date: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/unpayed/form?collectionDate=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipDocumentUnpayed = (ABA_REFs: any,date: any, choice: string) => {
    try {
        const response = axiosWithToken().get(`api/slips/unpayed?ABA_REFs=${ABA_REFs}&collectionDate=${date}&choice=${choice}&validation=false`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipDocumentUnpayedValidate = (ABA_REFs: any,date: any, choice: string) => {
    try {
        const response = axiosWithToken().get(`api/slips/unpayed?ABA_REFs=${ABA_REFs}&collectionDate=${date}&choice=${choice}&validation=true`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const deleteSlipBankRemittance = (ABA_REF: any, date: any) => {
    try {
        const response = axiosWithToken().delete(`api/slips/bankremittance?ABA_REF=${ABA_REF}&collectionDate=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const deleteSlipUnpayed= (ABA_REF: any, date: any) => {
    try {
        const response = axiosWithToken().delete(`api/slips/unpayed?ABA_REF=${ABA_REF}&collectionDate=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipBankTransfertFormData = (date: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/form/datas?paidUntil=${date}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsBankTransfert = (AMR_REF: number, date: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/form?paidUntil=${date}&AMR_REF=${AMR_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

// on voulait faire bien !== buroclic
/* export const checkDomiciliation = (ABA_REFs: any, date: any, AMR_REF: number) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/check/domiciliation?ABA_REFs=${ABA_REFs}&paidUntil=${date}&AMR_REF=${AMR_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}
 */

export const getSlipDocumentBankTransfert = (ABA_REFs: any, date: any, choice: string, remiseDate: any, AMR_REF: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer?ABA_REFs=${ABA_REFs}&paidUntil=${date}&choice=${choice}&validation=false&remiseDate=${remiseDate}&AMR_REF=${AMR_REF}`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsBankTransfertOne = (ABA_REF: number,AMR_REF: number, date: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/one?date=${date}&ABA_REF=${ABA_REF}&AMR_REF=${AMR_REF}&choice=DOS_CODE`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
} 

export const getSlipsBankRemittanceOne = (ABA_REF: number, date: any) => {
    const dateFormatted = dateFormattedFr(date)
    try {
        const response = axiosWithToken().get(`api/slips/bankremittance/one?collectionDate=${dateFormatted}&ABA_REF=${ABA_REF}&choice=DOS_CODE`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
} 

export const getSlipsUnpayedOne = (ABA_REF: number, date: any) => {
    const dateFormatted = dateFormattedFr(date)

    try {
        const response = axiosWithToken().get(`api/slips/unpayed/one?collectionDate=${dateFormatted}&ABA_REF=${ABA_REF}&choice=DOS_CODE`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
} 

export const getSlipsBankTransfertValidate = (ABA_REFs: any, date: any, choice: string, remiseDate: any, AMR_REF: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer?ABA_REFs=${ABA_REFs}&paidUntil=${date}&choice=${choice}&validation=false&remiseDate=${remiseDate}&AMR_REF=${AMR_REF}`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const getSlipsSchedules = (ABA_REF: any, date: any, AMR_REF: number)  => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/form/modify/schedules?ABA_REF=${ABA_REF}&date=${date}&AMR_REF=${AMR_REF}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
} 

export const getRibMandat = (PFIC_ID: any, FIC_CLE: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/form/modify/datas?PFIC_ID=${PFIC_ID}&FIC_CLE=${FIC_CLE}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const deleteSlipBankTransfertSchedule = (AEC_REFs: any) => {
    try {
        const response = axiosWithToken().delete(`api/slips/banktransfer/schedules?AEC_REFs=${AEC_REFs}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const bankTransferAuto = (AEC_REFs: any) => {
    try {
        const response = axiosWithToken().get(`api/slips/banktransfer/auto?AEC_REFs=${AEC_REFs}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}

export const updateSlipBankTransfertSchedule = (AEC_REF: any, data: any) => {
    try {
        const response = axiosWithToken().patch(`api/slips/banktransfer/${AEC_REF}`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || error.message);
          }
          throw error;
    }
}