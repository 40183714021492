import axios from "axios";
import { axiosWithToken, axiosWithTokenForzip } from "../axiosService";
/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get / post / patch / delete invoices
 */
export const getInvoices = () => {
    try {
        const response = axiosWithToken().get(`api/invoices`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}  

export const getMasks = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/masks`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getCredits = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/creditnote`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
};

export const getProvisional = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/provisional`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const postDeposit = (data: any) => {  
    try {
        const response = axiosWithToken().post(`api/invoices/provisional`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getProformats = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/proforma`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const getProformaMasks = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/proforma/masks`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const postProforma = ( data : any ) => {
    try {
        const response = axiosWithToken().post(`api/invoices/proforma`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
}

export const deleteProforma = ( idProforma : any[] ) => {
    try {
        const response = axiosWithToken().delete(`api/invoices/proforma?ANA_REF=${idProforma}` )
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
          }
          throw error;
    }
};

export const updateProforma = ( id: number, data : any ) => {
    try {
        const response = axiosWithToken().patch(`api/invoices/proforma/${id}`, data )
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getProformaById = ( id: number ) => {
    try {
        const response = axiosWithToken().get(`api/invoices/proforma/${id}`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getFeesLinkedToProforma = (id: any) => {
    try {
        const response = axiosWithToken().get(`api/invoices/proforma/${id}/billables`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getPdf = (id: any) => {
    try {
        const response = axiosWithToken().get(`api/invoices/${id}/document`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const validateProforma = (selectedIds: any) => {
    const data = {
        ANA_REFs: selectedIds
    }

    try {
        const response = axiosWithToken().post(`api/invoices/proforma/validate`, data)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const sendEmailForInvoice = (ANA_REF: any) => {
    try {
        const response = axiosWithToken().post(`api/invoices/${ANA_REF}/sendemail`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const invoiceZip = (ANA_REFs: any) => {
    try {
        const response = axiosWithTokenForzip().get(`api/invoices/zip?ANA_REFs=${ANA_REFs}`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
} 


export const getInvoicesRelaunchs = () => {
    try {
        const response = axiosWithToken().get(`api/invoices/opens`)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}  

export const postRelaunch = (data: any, id: any) => {
    const dataToSend = {
        ANA_REFs: data,
        MAT_ID: id
    }

    try {
        const response = axiosWithToken().post(`api/invoices/sendreminder`, dataToSend)
        return response
    }
    catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getSchedules = (ANA_REF: number) => {
    try{
        const response = axiosWithToken().get(`api/invoices/${ANA_REF}/schedules`)
        return response
    }  catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const postSchedule = (ANA_REF: number, data: any) => {
    try{
        const response = axiosWithToken().post(`api/invoices/${ANA_REF}/schedules`, data)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const getDomiciliation = (ANA_REF: number) => {
    try{
        const response = axiosWithToken().get(`api/invoices/${ANA_REF}/domiciliation/available`)
        return response
    } catch (error) {
       if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Une erreur est survenue");
            }
            throw error;
    }
}

export const patchSchedule = (ANA_REF: number, AEC_REF: number, data: any) => {
    try{
        const response = axiosWithToken().patch(`api/invoices/${ANA_REF}/schedule/${AEC_REF}`, data)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
             throw new Error(error.response?.data || "Une erreur est survenue");
             }
             throw error;
     }
}

export const proformatAutomaticFee = (ANA_REFs: string, AOR_REF: number) => {
    try{
        const response = axiosWithToken().get(`api/invoices/proforma/automaticfee?ANA_REFs=${ANA_REFs}&AOR_REF=${AOR_REF}`)
        return response
    } catch (error) {
        if (axios.isAxiosError(error)) {
             throw new Error(error.response?.data || "Une erreur est survenue");
             }
             throw error;
     }
}