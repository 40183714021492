import { useEffect, useRef } from "react";
import { useLoadingStore } from "../../../../store/utils/loading";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import RenderInterlocuteurEc from "./RenderInterlocuteurEc";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import RenderFournisseurEc from "./RenderFournisseurEc";
import { useExplorer } from "../../../../store/Crm/Explorer";
import {
  getOneParticipant,
  getParticipantsFields,
  updateParticipant,
} from "../../../../utils/participants";
import { errorFetch } from "../../../../utils/toaster";

const UpdateInterlocuteurEC = () => {
  const { isLoading, setIsLoading } = useLoadingStore();
  const {
    PDOS_ID,
    setPDOS_ID,
    PENT_ID,
    setPENT_ID,
    ficRefIntervenant,
    dosRef,
  } = useExplorer();
  const {
    choiceFicheType,
    setTypesFiches,
    setFields,
    setLocalCity,
    setLocalCp,
    setCity,
    setCp,
    data,
    setData,
    setSelectedGroup,
    setChoiceCreate,
    setDate,
    setDCLE,
    selectedGroup,
  } = useIntervenantStore();

  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const reset = () => {
    setTypesFiches([]);
    setFields([]);
    setLocalCity("");
    setLocalCp("");
    setSelectedGroup("");
    setData({});
    setChoiceCreate("");
  };

  useEffect(() => {
    if (
      ficRefIntervenant == undefined ||
      ficRefIntervenant == null ||
      ficRefIntervenant == 0
    ) {
      return;
    } else {
      setIsLoading(true);

      // Recupere les donnees du dossier client
      getOneParticipant(ficRefIntervenant)
        .then((res) => {
          setData(res.data);
          setLocalCity(res.data["Adresse principale (Ville)"]);
          setLocalCp(res.data["Adresse principale (CP)"]);
          setCity(res.data["Adresse principale (Ville)"]);
          setCp(res.data["Adresse principale (CP)"]);
          setPDOS_ID(res.data.PDOS_ID);
          setPENT_ID(res.data.PENT_ID);
          setDate(res.data.DDATE);
          setDCLE(res.data.DCLE);
          setIsLoading(false);
          // Initialisation du groupe implicite
          setSelectedGroup(
            res.data.DOS_CODETRAVAIL + " - " + res.data.DOS_LIBTRAVAIL
          );
        })
        .catch((err) => {
          setIsLoading(false);
          errorFetch();
          console.log(err);
        });
    }
  }, [ficRefIntervenant]);

  useEffect(() => {
    if (choiceFicheType === "Interlocuteurs") {
      getParticipantsFields(1, PENT_ID, PDOS_ID)
        .then((res) => {
          setFields(res.data);
        })
        .catch((err) => console.log(err));
    } else if (choiceFicheType === "Fournisseur") {
      getParticipantsFields(16, PENT_ID, PDOS_ID)
        .then((res) => {
          setFields(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      return;
    }
  }, [PDOS_ID, PENT_ID, ficRefIntervenant]);

  const handleUpdate = () => {
    setIsLoading(true);
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    // retire le DOS_CODE
    const { DOS_CODE, ...dataToUpdate } = data;

    if (selectedGroup !== "") {
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");
    }

    if (choiceFicheType === "Interlocuteurs") {
      const dataUpdate = {
        ...dataToUpdate,
        PDOS_ID,
        PENT_ID,
        PFIC_ID: 1,
        DOS_REF: dosRef,
        DOS_CODETRAVAIL,
        DOS_LIBTRAVAIL,
      };
      updateParticipant(dataUpdate)
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorFetch();
          setIsLoading(false);
        });
    } else if (choiceFicheType === "Fournisseur") {
      const dataUpdate = {
        ...dataToUpdate,
        PDOS_ID,
        PENT_ID,
        PFIC_ID: 16,
        DOS_REF: dosRef,
      };
      updateParticipant(dataUpdate)
        .then((res) => {
          console.log(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          errorFetch();
          setIsLoading(false);
        });
    } else {
      return;
    }
  };

  return !isLoading ? (
    <div
      ref={scrollableDivRef}
      className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-t-none rounded-b-md overflow-y-scroll text-sm"
    >
      <h2 className="my-5 text-xl font-bold">
        {choiceFicheType === "Interlocuteurs" ? "Interlocuteur" : "Fournisseur"}
      </h2>
      <div className="flex flex-col justify-start items-start w-full">
        {/* Affiche tous les inputs */}
        {choiceFicheType === "Interlocuteurs" ? (
          <RenderInterlocuteurEc />
        ) : choiceFicheType === "Fournisseur" ? (
          <RenderFournisseurEc />
        ) : null}

        <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
          <hr className="col-start-2 col-end-5 my-2" />
          <div className="col-start-4 col-end-5 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => {
                reset();
              }}
            >
              Fermer
            </button>
          </div>
          <div className="col-start-5 col-end-6 w-2/3 mb-5">
            <button
              className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
              onClick={() => handleUpdate()}
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
      <CircleLoarder />
    </div>
  );
};

export default UpdateInterlocuteurEC;
