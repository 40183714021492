import axios from "axios"
import { axiosWithToken } from "./axiosService"

/**
 * @date 2023
 * @author Succi Iris
 * @description Call API to get participants
 */

export const getParticipantsCategories = async (dosRef: number) => {
    try{
        const response = await axiosWithToken().get(`api/participants/categories?PDOS_ID=${dosRef}`)
        return response.data
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getParticipantsFields = async (PFIC_ID: any, PENT_ID : any, PDOS_ID: any ) => {
    try{
        const response = await axiosWithToken().get(`api/participants/fields?PFIC_ID=${PFIC_ID}&PENT_ID=${PENT_ID}&PDOS_ID=${PDOS_ID}`)
        return response
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const createParticipantInterlocuteur = async (data: any) => {
    try{
        const response = await axiosWithToken().post(`api/participants/interlocuteurs`, data)
        return response
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const createParticipantFournisseur = async (data: any) => {
    try{
        const response = await axiosWithToken().post(`api/participants/fournisseur`, data)
        return response
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const getOneParticipant = async (FIC_REF_CLIENT: any) => {
    try{
        const response = await axiosWithToken().get(`api/participants/${FIC_REF_CLIENT}`)
        return response
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}

export const updateParticipant = async (data: any) => {
    try{
        const response = await axiosWithToken().patch(`api/participants`, data)
        return response
    }
    catch(error){
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.statusText || "Une erreur est survenue");
        }
        throw error;
    }
}