import { useEffect } from "react";
import { useCreateFeesOrInvoices } from "../../../../store/Invoice/buttonsStore";
import { useSlip } from "../../../../store/Invoice/createSlip";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import { useLoadingStore } from "../../../../store/utils/loading";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import {
  getSlipBankRemittance,
  getSlipBankTransfertFormData,
  getSlipDocumentBankRemittance,
  getSlipDocumentBankRemittanceValidate,
  getSlipDocumentBankTransfert,
  getSlipDocumentUnpayed,
  getSlipDocumentUnpayedValidate,
  getSlipsBankTransfert,
  getSlipsBankTransfertValidate,
  getSlipsUnpayed,
} from "../../../../utils/Invoice/slips";
import { useCreateInvoiceStore } from "../../../../store/Invoice/createInvoice";
import chevron from "../../../../assets/icons/chevron-right.svg";
import { useInvoiceStore } from "../../../../store/Invoice/invoice";
import { errorFetch } from "../../../../utils/toaster";
/**
 * @name CreateSchedule
 * @description
 * Ce composant gère la création d'un bordereau
 * @date 10/11/2024
 * @author Iris Succi
 * @returns {JSX.Element} - The rendered component.
 */
const CreateSlip = () => {
  const { createSlip, setCreateSlip, setVisuPdf } = useCreateFeesOrInvoices();
  const { animationClass, setAnimationClass, displayDiv, setDisplayDiv } =
    useCreateInvoiceStore();
  const { setDisplayDivVisuPdf } = useCreateInvoiceStore();
  const { setDocumentUrl } = useInvoiceStore();
  const {
    startDate,
    setStartDate,
    selectedBankSlip,
    setSelectedBankSlip,
    operation,
    setOperation,
    modes,
    setModes,
    type,
    setType,
    selectedOption,
    setSelectedOption,
    slips,
    setSlips,
    reset,
    targetDate,
    setTargetDate,
    setSlipFormData,
    selectedMode,
    setSelectedMode,
    verifySchedule,
    setVerifySchedule,
  } = useSlip();
  const { setIsLoading } = useLoadingStore();
  const { setSendData } = useTabsStore();

  /**
   * @function
   * @description Fonction qui permet de gérer l'affichage du composant
   * @author Succi Iris
   * @date 2023
   */
  useEffect(() => {
    if (createSlip === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [createSlip]);

  /**
   * @function
   * @description Fonction de CREER ET DE VALIDER d'un bordereau en fonction de la variable operation
   * @author Succi Iris
   * @date 2024
   */
  const handleCreateSlip = () => {
    setCreateSlip(false);
    setIsLoading(true);
    if (operation === "remise en banque") {
      getSlipDocumentBankRemittanceValidate(
        selectedBankSlip.join(),
        startDate,
        selectedOption
      )
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch(() => {
          setIsLoading(false);
          errorFetch();
        });
    } else if (operation === "impayés") {
      getSlipDocumentUnpayedValidate(
        selectedBankSlip.join(),
        startDate,
        selectedOption
      )
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          // Recupère le blob et le convertit en URL pour afficher le pdf
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch(() => {
          setIsLoading(false);
          errorFetch();
        });
    } else if (operation === "envoi d'écritures à la banque") {
      getSlipsBankTransfertValidate(
        selectedBankSlip.join(),
        startDate,
        selectedOption,
        targetDate,
        selectedMode
      )
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch(() => {
          setIsLoading(false);
          errorFetch();
        });
    }
  };

  /**
   * @function
   * @description Fonction de CREER ET SANS VALIDER d'un bordereau en fonction de la variable operation
   * @author Succi Iris
   * @date 2024
   */
  // Visualiser un bordereau en PDF sans le valider
  const handleVisuSlip = () => {
    if (operation === "remise en banque") {
      setCreateSlip(false);
      setIsLoading(true);
      getSlipDocumentBankRemittance(
        selectedBankSlip.join(),
        startDate,
        selectedOption
      )
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          // Recupère le blob et le convertit en URL pour afficher le pdf
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch(() => {
          setIsLoading(false);
          errorFetch();
        });
    } else if (operation === "impayés") {
      setCreateSlip(false);
      setIsLoading(true);
      getSlipDocumentUnpayed(selectedBankSlip.join(), startDate, selectedOption)
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch(() => {
          setIsLoading(false);
          errorFetch();
        });
    } else if (operation === "envoi d'écritures à la banque") {
      setIsLoading(true);
      getSlipDocumentBankTransfert(
        selectedBankSlip.join(),
        startDate,
        selectedOption,
        targetDate,
        selectedMode
      )
        .then((res) => {
          setVisuPdf(true);
          setDisplayDivVisuPdf(true);
          setIsLoading(false);
          reset();
          const newPdfUrl = URL.createObjectURL(res.data);
          setIsLoading(false);
          setDocumentUrl(newPdfUrl);
          setSendData((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCheckboxChange = (option: string) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (operation === "remise en banque") {
      // Recupere les bordereaux remise en banque a valider
      getSlipBankRemittance(startDate)
        .then((res) => {
          setSlips(res.data);
        })
        .catch((err) => console.log(err));
    } else if (operation === "impayés") {
      // Recupere les bordereaux impayés a valider
      getSlipsUnpayed(startDate)
        .then((res) => {
          setSlips(res.data);
        })
        .catch((err) => console.log(err));
    } else if (operation === "envoi d'écritures à la banque") {
      // Recupère les données du formulaire a pre-remplir
      getSlipBankTransfertFormData(startDate)
        .then((res) => {
          setSlipFormData(res.data);
          const parsedTargetDate = new Date(res.data.targetDate);
          setTargetDate(parsedTargetDate);
          setModes(res.data.A_MODE_REGLEMENT);
        })
        .catch((err) => console.log(err));

      if (selectedMode !== 0) {
        // Recupere les bordereaux envoi d'écritures à la banque a valider
        getSlipsBankTransfert(selectedMode, startDate)
          .then((res) => {
            setSlips(res.data);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [operation, startDate, selectedMode]);

  return (
    <>
      {displayDiv ? (
        <div
          className={`absolute top-0 right-0 w-[68%] h-full bg-bc-lightGrey z-10`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex justify-start items-start h-full w-full overflow-y-scroll">
            <button
              className="h-full w-10 flex justify-center items-center"
              style={{
                animation: animationClass,
              }}
              onClick={() => {
                setCreateSlip(false);
              }}
            >
              <img src={chevron} alt="fleche" />
            </button>
            <div className="flex flex-col justify-start items-start w-[80%] h-full m-auto">
              <p className="font-bold text-lg my-5 text-center w-full">
                Editer un bordereau
              </p>
              <div className="w-full gap-4 flex flex-col">
                <div className="grid grid-cols-7">
                  <p className="text-lg font-bold col-start-1 col-end-3 my-4">
                    Opérations bancaires
                  </p>
                  <div className="flex flex-col justify-start items-start mr-6 col-start-1 col-end-3">
                    <p>Opération à effectuer : </p>
                    <select
                      name="operation"
                      id="operation"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                      value={operation}
                      onChange={(event) => {
                        setOperation(event.target.value);
                      }}
                    >
                      <option value="remise en banque">remise en banque</option>
                      <option value="impayés">impayés</option>
                      <option value="envoi d'écritures à la banque">
                        envoi d'écritures à la banque
                      </option>
                    </select>
                  </div>
                  <p className="text-lg font-bold col-start-1 col-end-6 mb-4 mt-8">
                    Edition des bordereaux de {`${operation}`}
                  </p>

                  {operation === "envoi d'écritures à la banque" ? (
                    <>
                      {/* Envoi d'écritures à la banque */}
                      <div className="flex flex-col justify-start items-start mr-6 col-start-1 col-end-3 z-10">
                        <p>Réglé jusqu'au :</p>
                        <Datepicker
                          startDate={startDate}
                          setStartDate={setStartDate}
                        />
                      </div>
                      <div className="flex flex-col justify-start items-start mr-6 col-start-4 col-end-6">
                        <p>Date de remise :</p>
                        <Datepicker
                          startDate={targetDate}
                          setStartDate={setTargetDate}
                        />
                      </div>
                      <div className="flex flex-col justify-start items-start mr-6 col-start-1 col-end-3">
                        <p>Mode de reglement :</p>
                        <select
                          name="modeReglement"
                          id="modeReglement"
                          className="w-full border-[1px] rounded border-gray-300 h-9 p-1 "
                          value={selectedMode}
                          onChange={(event) => {
                            setSelectedMode(event.target.value);
                          }}
                        >
                          {modes?.map((mode: any, i: any) => (
                            <option key={i} value={mode.AMR_REF}>
                              {mode.AMR_LIBELLE}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="flex flex-col justify-start items-start mr-6 col-start-4 col-end-6">
                        <p>Type de reglement :</p>
                        <select
                          name="typeReglement"
                          id="typeReglement"
                          className="w-full border-[1px] rounded border-gray-300 h-9 p-1"
                          value={type}
                          onChange={(event) => {
                            setType(event.target.value);
                          }}
                        >
                          <option value="Prélèvement ordinaire (08)">
                            {" "}
                            Prélèvement ordinaire (08){" "}
                          </option>
                          <option value=" Prélèvement accéléré (85) ">
                            {" "}
                            Prélèvement accéléré (85){" "}
                          </option>
                        </select>
                      </div>
                      <div className="flex flex-col justify-start items-start col-start-4 col-end-6">
                        <div className="flex">
                          <input
                            type="checkbox"
                            checked={selectedOption === "simple"}
                            onChange={() => handleCheckboxChange("simple")}
                            className="mr-4"
                          />
                          <p>Bordereau simple</p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            checked={selectedOption === "codeDossier"}
                            onChange={() => handleCheckboxChange("codeDossier")}
                            className="mr-4"
                          />
                          <p>Bordereau avec code dossier</p>
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-start col-start-1 col-end-7">
                        <p>Séléctionner les bordereaux :</p>
                        <div className="w-full m-auto border-[1px] rounded-md h-44 flex flex-col justify-start items-center bg-white overflow-y-scroll">
                          {slips?.map((slip: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full justify-start items-center h-10 border-b-[1px] border-gray-300"
                            >
                              <input
                                type="checkbox"
                                value={slip.ABA_REF}
                                className="mx-4"
                                checked={selectedBankSlip.includes(
                                  slip.ABA_REF
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedBankSlip([
                                      ...selectedBankSlip,
                                      slip.ABA_REF,
                                    ]);
                                  } else {
                                    setSelectedBankSlip(
                                      selectedBankSlip.filter(
                                        (item) => item !== slip.ABA_REF
                                      )
                                    );
                                  }
                                }}
                              />
                              <p>{slip.text}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      {!verifySchedule ? (
                        <div className="flex flex-col justify-start items-start col-start-1 col-end-3 mt-8">
                          <button
                            className="w-44 h-8 mr-4 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md"
                            onClick={() => setVerifySchedule(true)}
                          >
                            Vérifier les échéances
                          </button>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {/* REMISE EN BANQUE + IMPAYES */}
                      <div className="flex flex-col justify-start items-start mr-6 col-start-1 col-end-3">
                        <p>Date :</p>
                        <Datepicker
                          startDate={startDate}
                          setStartDate={setStartDate}
                        />
                      </div>
                      <div className="flex flex-col justify-start items-start col-start-4 col-end-6">
                        <div className="flex">
                          <input
                            type="checkbox"
                            checked={selectedOption === "simple"}
                            onChange={() => handleCheckboxChange("simple")}
                            className="mr-4"
                          />
                          <p>Bordereau simple</p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            checked={selectedOption === "codeDossier"}
                            onChange={() => handleCheckboxChange("codeDossier")}
                            className="mr-4"
                          />
                          <p>Bordereau avec code dossier</p>
                        </div>
                      </div>
                      <div className="flex flex-col justify-start items-start col-start-1 col-end-7">
                        <p>Séléctionner les bordereaux :</p>
                        <div className="w-full m-auto border-[1px] rounded-md h-44 flex flex-col justify-start items-center bg-white overflow-y-scroll">
                          {slips?.map((slip: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full justify-start items-center h-10 border-b-[1px] border-gray-300"
                            >
                              <input
                                type="checkbox"
                                value={slip.ABA_REF}
                                className="mx-4"
                                checked={selectedBankSlip.includes(
                                  slip.ABA_REF
                                )}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedBankSlip([
                                      ...selectedBankSlip,
                                      slip.ABA_REF,
                                    ]);
                                  } else {
                                    setSelectedBankSlip(
                                      selectedBankSlip.filter(
                                        (item) => item !== slip.ABA_REF
                                      )
                                    );
                                  }
                                }}
                              />
                              <p>{slip.text}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="flex justify-end w-full items-end col-start-4 col-end-6 mt-8">
                    <button
                      className="w-20 h-8 mr-4 border-[1px] rounded-md border-rose-600 bg-rose-600 hover:border-rose-500 hover:bg-rose-5§00 text-white shadow-md"
                      onClick={() => {
                        setCreateSlip(false);
                        setDisplayDiv(false);
                      }}
                    >
                      Annuler{" "}
                    </button>
                    <button
                      className="w-20 h-8 mr-4 border-[1px] rounded-md border-bc-orange bg-bc-orange hover:border-bc-lightOrange hover:bg-bc-lightOrange text-white shadow-md"
                      onClick={() => {
                        handleVisuSlip();
                      }}
                    >
                      Visualiser
                    </button>
                    <button
                      className="w-20 h-8 border-[1px] rounded-md border-bc-green bg-bc-green hover:border-bc-lightGreen hover:bg-bc-lightGreen text-white shadow-md"
                      onClick={() => {
                        handleCreateSlip();
                      }}
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateSlip;
