import { useEffect, useState } from "react";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";
import { useLoadingStore } from "../../../../store/utils/loading";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
import RenderInterlocuteurEc from "./RenderInterlocuteurEc";
import { getTypeFiches } from "../../../../utils/folders";
import { useExplorer } from "../../../../store/Crm/Explorer";
import RenderFournisseurEc from "./RenderFournisseurEc";
import {
  createParticipantFournisseur,
  createParticipantInterlocuteur,
  getParticipantsFields,
} from "../../../../utils/participants";
import { interlocuteurCreated } from "../../../../utils/toaster";

const CreateIntervenantEc = () => {
  const { isLoading, setIsLoading } = useLoadingStore();
  const {
    typesFiches,
    setTypesFiches,
    setFields,
    localCity,
    localCp,
    selectedGroup,
    setLocalCity,
    setLocalCp,
    data,
    setData,
    setSelectedGroup,
    setChoiceCreate,
  } = useIntervenantStore();
  const { PDOS_ID, dosRef, setPENT_ID, setPDOS_ID, setFicRefIntervenant } =
    useExplorer();
  const [choiceIntervenant, setChoiceIntervenant] = useState<any | null>(null);

  useEffect(() => {
    getTypeFiches(PDOS_ID)
      .then((res) => {
        const formattedData = res.data.map((item: any) => {
          return {
            // Obligé de concaténer les 3 ID pour avoir une valeur unique
            value: item.PFIC_ID + "-" + item.PENT_ID + "-" + PDOS_ID,
            label: item.PENT_NOM,
          };
        });
        setTypesFiches(formattedData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (choiceIntervenant === null) {
      setIsLoading(false);
      return;
    } else {
      setData({});
      setLocalCp("");
      setLocalCity("");
      setIsLoading(true);
    }
    const PFIC_ID = choiceIntervenant?.value?.split("-")[0];
    const PENT_ID = choiceIntervenant?.value?.split("-")[1];
    const PDOS_ID = choiceIntervenant?.value?.split("-")[2];
    getParticipantsFields(PFIC_ID, PENT_ID, PDOS_ID)
      .then((res) => {
        setFields(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [choiceIntervenant]);

  const handleCreateInterlocuteur = () => {
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    setIsLoading(true);
    if (selectedGroup !== "")
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");

    const PFIC_ID = choiceIntervenant?.value?.split("-")[0];
    const PENT_ID = choiceIntervenant?.value?.split("-")[1];
    const PDOS_ID = choiceIntervenant?.value?.split("-")[2];
    const dataToSend = {
      PFIC_ID,
      PENT_ID,
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
      PDOS_ID,
      "Adresse principale (CP)": localCp,
      "Adresse principale (Ville)": localCity,
      DOS_REF: dosRef,
      ...data,
    };

    createParticipantInterlocuteur(dataToSend)
      .then((res) => {
        setIsLoading(false);
        setData({});
        setLocalCp("");
        setLocalCity("");
        interlocuteurCreated();
        setChoiceCreate("UpdateIntervenant");
        setPDOS_ID(PDOS_ID);
        setPENT_ID(PENT_ID);
        setFicRefIntervenant(res.data.FIC_REF_CLIENT);
      })
      .catch((err) => console.log(err));
  };

  const handleCreateFournisseur = () => {
    let DOS_CODETRAVAIL;
    let DOS_LIBTRAVAIL;

    setIsLoading(true);

    if (selectedGroup !== "")
      [DOS_CODETRAVAIL, DOS_LIBTRAVAIL] = selectedGroup.split(" - ");

    const PFIC_ID = choiceIntervenant?.value?.split("-")[0];
    const PENT_ID = choiceIntervenant?.value?.split("-")[1];
    const PDOS_ID = choiceIntervenant?.value?.split("-")[2];
    const dataToSend = {
      PFIC_ID,
      PENT_ID,
      DOS_CODETRAVAIL,
      DOS_LIBTRAVAIL,
      PDOS_ID,
      "Adresse principale (CP)": localCp,
      "Adresse principale (Ville)": localCity,
      DOS_REF: dosRef,
      ...data,
    };

    createParticipantFournisseur(dataToSend)
      .then((res) => {
        setData({});
        setLocalCp("");
        setLocalCity("");
        setSelectedGroup("");
        setChoiceCreate("UpdateIntervenant");
        setPDOS_ID(PDOS_ID);
        setPENT_ID(PENT_ID);
        setFicRefIntervenant(res.data.FIC_REF_CLIENT);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="2xl:relative w-full h-full flex flex-col bg-bc-lightGrey justify-start items-center border-[1px] border-bc-lightGrey rounded-md overflow-y-scroll text-sm">
      <h2 className="my-5 text-xl font-bold">Création d'un participant</h2>

      <div className="flex flex-col justify-start items-start w-full">
        <div className="grid grid-cols-5  w-11/12 m-auto gap-4">
          <div className="col-start-1 col-end-2 w-full flex justify-start items-center">
            <p>Type de fiche </p>
          </div>
          <div className="col-start-2 col-end-3 w-full">
            <select
              name="typeFiche"
              id="typeFiche"
              className="border-[1px] rounded-md w-full p-2"
              onChange={(e) => {
                const selectedOption = typesFiches?.find(
                  (item: any) => item.value === e.target.value
                );
                setChoiceIntervenant(selectedOption);
              }}
            >
              <option value="" className="text-slate-300 "></option>
              {typesFiches?.map((item: any) => (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
          <hr className="col-start-2 col-end-5 my-2" />
        </div>
        {!isLoading ? (
          <div className="w-full flex flex-col justify-start items-center">
            {choiceIntervenant?.label === "Interlocuteurs" ? (
              <RenderInterlocuteurEc />
            ) : choiceIntervenant?.label === "Fournisseur" ? (
              <RenderFournisseurEc />
            ) : null}

            <hr className="col-start-2 col-end-5 my-2" />

            {choiceIntervenant?.label === "Interlocuteurs" ||
            choiceIntervenant?.label === "Fournisseur" ? (
              <div className="grid grid-cols-5 w-11/12 m-auto gap-4">
                <div className="col-start-4 col-end-5 w-2/3 mb-5">
                  <button
                    className="w-full mt-2 h-9 bg-red-500 rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                    onClick={() => {
                      setData({});
                      setLocalCp("");
                      setChoiceCreate("");
                      setSelectedGroup("");
                      setLocalCity("");
                    }}
                  >
                    Annuler
                  </button>
                </div>
                <div className="col-start-5 col-end-6 w-2/3 mb-5">
                  <button
                    className="w-full mt-2 h-9 bg-bc-green rounded flex justify-center items-center mr-2 text-white px-2 shadow-md"
                    onClick={() =>
                      choiceIntervenant?.label === "Interlocuteurs"
                        ? handleCreateInterlocuteur()
                        : handleCreateFournisseur()
                    }
                  >
                    Créer
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="h-full w-full col-start-1 col-end-6 flex justify-center items-center">
            <CircleLoarder />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateIntervenantEc;
