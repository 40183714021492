import { useTranslation } from "react-i18next";
import Select from "react-select";
import { SelectOptionDossier } from "../../../../interface/selects";
import { useEffect } from "react";
import { getAllDossiers } from "../../../../utils/folders";
import { getMissionByDosRef } from "../../../../utils/missions";
import { getTimeFilterLibelles } from "../../../../utils/TimeManager/timeFilter";
import { dataInvoicesFeelsStore } from "../../../../store/Invoice/dataInvoicesFeelsStore";
import { useLoadingStore } from "../../../../store/utils/loading";
import { nodatamatches } from "../../../../utils/toaster";
import { getFeesOrigins } from "../../../../utils/Invoice/feesEntry";
import { filterInvoicesStore } from "../../../../store/Invoice/filterInvoicesStore";
import { useTabsStore } from "../../../../store/Invoice/tabsStore";
import {
  getInvoices,
  getInvoicesRelaunchs,
  getProformats,
  getProvisional,
} from "../../../../utils/Invoice/invoicing";
import { getAllCredits } from "../../../../utils/Invoice/credits";
import { getPrepayments } from "../../../../utils/Invoice/prepayments";
import { getCashInFlows } from "../../../../utils/Invoice/cashInFlow";
import DatepickerForFilter from "./DatePickerForFilter";
import {
  getSlipsBankRemittanceArray,
  getSlipsUnpayedArray,
} from "../../../../utils/Invoice/slips";
import { useCreateFee } from "../../../../store/Invoice/createFeeStore";
import { getAllFees, getFeesBillables } from "../../../../utils/Invoice/fees";

/**
 * @component
 * @name FilterFees
 * @description Modal for filtering fees.
 * @author Succi Iris
 * @date 2023
 */
const FilterFees = ({ closeModal }: any) => {
  // Translate
  const { t } = useTranslation();
  // Store
  const {
    missions,
    setMissions,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    selectedMisRef,
    setSelectedMisRef,
    selectedFileOption,
    setSelectedFileOption,
    selectedOriginObject,
    setSelectedOriginObject,
    selectedFile,
    setSelectedFile,
    selectedMission,
    setSelectedMission,
    selectedOrigin,
    setSelectedOrigin,
    selectedLibelle,
    setSelectedLibelle,
    fileOptions,
    setFileOptions,
    origins,
    setOrigins,
    libelles,
    setLibelles,
    setSelectedLibelleObject,
    optionNonFacturable,
    setOptionNonFacturable,
  } = filterInvoicesStore();
  const { choiceTab } = useTabsStore();
  const {
    setFeesFilter,
    setInvoicesFilter,
    setCreditsFilter,
    setCashInFlowFilters,
    setAcomptesFilter,
    setProformatsFilter,
    setProvisionalFilter,
    setSlipsFilter,
    setRelaunchsFilter,
  } = dataInvoicesFeelsStore();
  const { setSendFee } = useCreateFee();
  const { setIsLoading } = useLoadingStore();

  /**
   * @function
   * @name deleteFilter
   * @description Deletes all filters.
   * @author Succi Iris
   * @date 2023
   */
  const deleteFilter = () => {
    setSelectedOrigin("");
    setSelectedLibelle("");
    setSelectedFile(0);
    setSelectedMission("");
    setStartDateFilter(null);
    setEndDateFilter(null);
    setSelectedLibelleObject(null);
    setSelectedOriginObject(null);
    setSelectedFileOption(null);
    setSendFee(false);
    setOptionNonFacturable(false);
  };

  /**
   * @function
   * @name getAllDossiers
   * @description Call api pour récupérer tous les dossiers
   * @author Succi Iris
   * @date 2023
   */
  useEffect(() => {
    getAllDossiers()
      .then((res) => {
        const formattedFiles = res.data.map((file: any) => ({
          value: file.DOS_REF,
          label: file.FIC_TITRE_CLIENT,
        }));
        setFileOptions(formattedFiles);
      })
      .catch((err) => console.log(err));
  }, []);

  // Call api pour récupérer toutes les missions
  useEffect(() => {
    if (selectedFile !== 0) {
      getMissionByDosRef(selectedFile)
        .then((res) => {
          const filteredMissions = res?.data.reduce(
            (missions: any[], mission: any) => {
              if (mission.MIS_DEBUT !== null) {
                const missionYear = new Date(mission.MIS_DEBUT).getFullYear();

                const existingMission = missions.find(
                  (item) => item.MIS_LIBELLE === mission.MIS_LIBELLE
                );

                if (existingMission) {
                  const existingMissionYear = new Date(
                    existingMission.MIS_DEBUT
                  ).getFullYear();
                  if (startDateFilter && endDateFilter) {
                    if (
                      Math.abs(missionYear - startDateFilter.getFullYear()) <
                      Math.abs(
                        existingMissionYear - startDateFilter.getFullYear()
                      )
                    ) {
                      missions = missions.filter(
                        (item) => item.MIS_LIBELLE !== mission.MIS_LIBELLE
                      );
                      missions.push(mission);
                    }
                  }
                } else {
                  missions.push(mission);
                }
              } else {
                missions.push(mission);
              }

              return missions;
            },
            []
          );

          const formattedMissions = filteredMissions.map((mission: any) => ({
            value: mission.MIS_CODE,
            label: mission.MIS_DEBUT
              ? `${new Date(mission.MIS_DEBUT).getFullYear()} - ${
                  mission.MIS_LIBELLE
                }`
              : mission.MIS_LIBELLE,
            misRef: mission.MIS_REF,
          }));

          setMissions(formattedMissions);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedFile, startDateFilter]);

  // Call api pour récupérer toutes les origines
  useEffect(() => {
    if (selectedFile !== 0 && selectedMisRef) {
      getFeesOrigins(selectedFile, selectedMisRef)
        .then((res) => {
          const formattedOrigins = res.data.map((origin: any) => ({
            value: origin.AOR_REF,
            label: origin.AOR_LIBELLE,
          }));
          setOrigins(formattedOrigins);
        })
        .catch((err) => console.log(err));

      const storedValue = localStorage.getItem("selectedOrigin");
      if (storedValue) {
        const originObject = origins.find(
          (origin) => origin.label === storedValue
        );
        setSelectedOriginObject(originObject);
      }
    }
  }, [selectedFile, selectedMisRef, selectedMission]);

  /**
   * @description Fetch all libelles based on the selected origin
   * @param {string} selectedOrigin - The selected origin.
   */
  useEffect(() => {
    if (selectedOrigin !== "" && selectedOriginObject) {
      getTimeFilterLibelles(selectedOriginObject.value)
        .then((res) => {
          const formattedLibelles = res.data.map((libelle: any) => ({
            value: libelle.ALI_CODE,
            label: libelle.ALI_LIBELLE,
          }));
          setLibelles(formattedLibelles);
        })
        .catch((err) => console.log(err));
    }

    const storedValue = localStorage.getItem("selectedLibelle");
    if (storedValue) {
      libelles.find((libelle) => libelle.label === storedValue);
    }
  }, [selectedOrigin]);

  /**
   * @function
   * @name handleFilter
   * @description Filters the data based on the selected filters.
   * @param {string} selectedOrigin - The selected origin.
   */
  const handleFilter = () => {
    setIsLoading(true);
    closeModal();
    let promise: Promise<any>;

    if (choiceTab === "Honoraire") {
      if (optionNonFacturable) {
        promise = getAllFees();
      } else {
        promise = getFeesBillables();
      }
    } else if (choiceTab === "Facture") {
      promise = getInvoices();
    } else if (choiceTab === "Avoir") {
      promise = getAllCredits();
    } else if (choiceTab === "Acompte") {
      promise = getPrepayments();
    } else if (choiceTab === "Provision") {
      promise = getProvisional();
    } else if (choiceTab === "Proformat") {
      promise = getProformats();
    } else if (choiceTab === "Encaissement") {
      promise = getCashInFlows();
    } else if (choiceTab === "Bordereau") {
      promise = getSlipsBankRemittanceArray();
      promise = getSlipsUnpayedArray();
    } else if (choiceTab === "Relance") {
      promise = getInvoicesRelaunchs();
    } else {
      promise = new Promise((reject) => {
        reject("error");
      });
    }

    promise
      .then((res) => {
        let filteredData = res.data;
        console.log(filteredData);
        // Vérifie si startDateFilter est null ou undefined
        if (startDateFilter && endDateFilter && choiceTab !== "Relance") {
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date >= startDateFilter && date <= endDateFilter;
          });
        } else if (startDateFilter && choiceTab !== "Relance") {
          // Applique le filtre avec seulement startDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date >= startDateFilter;
          });
        } else if (endDateFilter && choiceTab !== "Relance") {
          // Applique le filtre avec seulement endDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATEREALISATION);
            return date <= endDateFilter;
          });
        }

        // Vérifie si startDateFilter est null ou undefined
        if (startDateFilter && endDateFilter && choiceTab === "Relance") {
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATESAISIE);
            return date >= startDateFilter && date <= endDateFilter;
          });
        } else if (startDateFilter && choiceTab === "Relance") {
          // Applique le filtre avec seulement startDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATESAISIE);
            return date >= startDateFilter;
          });
        } else if (endDateFilter && choiceTab === "Relance") {
          // Applique le filtre avec seulement endDateFilter
          filteredData = filteredData.filter((item: any) => {
            const date = new Date(item.ANA_DATESAISIE);
            return date <= endDateFilter;
          });
        }

        // Filter by file
        if (selectedFile !== 0) {
          filteredData = filteredData.filter((item: any) => {
            return item?.DOS_REF === selectedFile;
          });
        }

        // Filter by mission
        if (selectedMission !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.MIS_REF === selectedMisRef
          );
        }

        if (selectedOrigin !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.AOR_LIBELLE === selectedOrigin
          );
        }

        if (selectedLibelle !== "") {
          filteredData = filteredData.filter(
            (item: any) => item.ANA_LIBELLELIB === selectedLibelle
          );
        }

        // This is for AOR_OPTION_FACTURABLE filter
        if (optionNonFacturable) {
          filteredData = filteredData.filter(
            (item: any) => item.AOR_OPTION_FACTURABLE === 0
          );
        } else {
          filteredData = filteredData.filter(
            (item: any) => item.AOR_OPTION_FACTURABLE === 1
          );
        }

        if (choiceTab === "Honoraire") {
          setFeesFilter(filteredData);
        } else if (choiceTab === "Facture") {
          setInvoicesFilter(filteredData);
        } else if (choiceTab === "Avoir") {
          setCreditsFilter(filteredData);
        } else if (choiceTab === "Acompte") {
          setAcomptesFilter(filteredData);
        } else if (choiceTab === "Provision") {
          setProvisionalFilter(filteredData);
        } else if (choiceTab === "Proformat") {
          setProformatsFilter(filteredData);
        } else if (choiceTab === "Encaissement") {
          setCashInFlowFilters(filteredData);
        } else if (choiceTab === "Bordereau") {
          setSlipsFilter(filteredData);
        } else if (choiceTab === "Relance") {
          setRelaunchsFilter(filteredData);
        } else {
          promise = new Promise((reject) => {
            reject("error");
          });
        }

        setSelectedMission("");
        setSelectedOrigin("");
        setSelectedLibelle("");
        setSelectedFile(0);
        setSelectedMisRef(0);
        setFileOptions([]);
        setSelectedFileOption(null);
        setMissions([]);
        setOrigins([]);
        setLibelles([]);
        setStartDateFilter(null);
        setEndDateFilter(null);

        if (filteredData.length === 0) {
          nodatamatches();
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  /**
   * @function
   * @name handleTitle
   * @description Returns the title of the modal based on the selected tab.
   */
  const handleTitle = () => {
    let title = "";
    if (choiceTab === "Honoraire") {
      title = "Honoraire";
    } else if (choiceTab === "Facture") {
      title = "Facture";
    } else if (choiceTab === "Avoir") {
      title = "Avoir";
    } else if (choiceTab === "Acompte") {
      title = "Acompte";
    } else if (choiceTab === "Provision") {
      title = "Provision";
    } else if (choiceTab === "Proformat") {
      title = "Proformat";
    } else if (choiceTab === "Encaissement") {
      title = "Encaissement";
    } else if (choiceTab === "Bordereau") {
      title = "Bordereau";
    } else if (choiceTab === "Relance") {
      title = "Relance";
    } else {
      title = "";
    }
    return title;
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[55%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl text-center">
                {t("Filtre")} {handleTitle()}
              </h3>
            </div>
            {/*body*/}
            <div className="w-11/12 m-auto font-light mt-2 flex flex-col">
              <div className="flex w-[70%] m-auto justify-between items-center">
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Du")} :</p>
                  <DatepickerForFilter
                    startDate={startDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setStartDateFilter(date);
                    }}
                  />
                </div>
                <div className="flex justify-center items-center z-50">
                  <p className="mr-2 mb-2">{t("Au")} :</p>
                  <DatepickerForFilter
                    startDate={endDateFilter}
                    setStartDate={(date: Date | null) => {
                      if (date) setEndDateFilter(date);
                    }}
                  />
                </div>
              </div>
              <hr className="w-8/12 m-auto mb-2" />
              <div className="flex flex-col w-10/12 m-auto justify-between items-center">
                <div className="grid grid-cols-5 w-full">
                  <div className="flex flex-col col-start-1 col-end-3">
                    <p className="mr-2">{t("Dossier")}: </p>
                    <Select
                      options={fileOptions}
                      value={selectedFileOption}
                      isClearable
                      className="w-full h-9 z-30 font-thin"
                      isSearchable
                      onChange={(
                        selectedOption: SelectOptionDossier | null
                      ) => {
                        const selectedValue = selectedOption?.value || 0;
                        setSelectedFile(selectedValue);
                        setSelectedFileOption(selectedOption);
                      }}
                    />
                  </div>

                  {choiceTab !== "Relance" ? (
                    <>
                      <div className="flex flex-col col-start-4 col-end-6 ">
                        <p className="mr-2">{t("Mission")}: </p>
                        <select
                          name="mission"
                          id="mission"
                          className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                          value={selectedMission}
                          onChange={(event) => {
                            const selectedValue = event.target.value || "";
                            const selectedMissionObject = missions.find(
                              (mission) => mission.value === selectedValue
                            );
                            if (selectedMissionObject) {
                              setSelectedMission(selectedMissionObject.value);
                              setSelectedMisRef(selectedMissionObject.misRef);
                              localStorage.setItem(
                                "selectedMission",
                                JSON.stringify(selectedMissionObject.value)
                              );
                            }
                          }}
                        >
                          <option value={""}></option>
                          {missions.map((mission) => (
                            <option value={mission?.value}>
                              {mission?.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col col-start-1 col-end-3 my-2">
                        <p className="mr-3">{t("Origine")}: </p>
                        <select
                          name="Origine"
                          id="Origine"
                          className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                          value={selectedOrigin || ""}
                          onChange={(event) => {
                            setSelectedOrigin(event.target.value);
                            localStorage.setItem(
                              "selectedOrigin",
                              event.target.value
                            );
                            const originObject = origins.find(
                              (origin) => origin.label === event.target.value
                            );
                            setSelectedOriginObject(originObject);
                          }}
                        >
                          <option value={""}></option>
                          {origins.map((origin) => (
                            <option key={origin.value} value={origin.label}>
                              {origin.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col col-start-4 col-end-6 my-2">
                        <p className="mr-5">{t("Libellé")}: </p>
                        <select
                          name="Libelle"
                          id="Libelle"
                          className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 font-thin"
                          onChange={(event) => {
                            setSelectedLibelle(event.target.value);
                            localStorage.setItem(
                              "selectedLibelle",
                              event.target.value
                            );
                            const libelleObjet = libelles.find(
                              (libelle) => libelle.label === event.target.value
                            );
                            setSelectedLibelleObject(libelleObjet);
                          }}
                        >
                          <option value={""}></option>
                          {libelles.map((libelle) => (
                            <option key={libelle.value} value={libelle.label}>
                              {libelle.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      {choiceTab === "Honoraire" ? (
                        <div className="flex justify-start items-center col-start-1 col-end-4 my-2">
                          <input
                            type="checkbox"
                            name="billableItems"
                            id="billableItems"
                            className="mr-2"
                            checked={optionNonFacturable}
                            onChange={() =>
                              setOptionNonFacturable(!optionNonFacturable)
                            }
                          />
                          <p className="mr-3">
                            Afficher les éléments non facturables{" "}
                          </p>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-bc-orange background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  deleteFilter();
                  closeModal();
                }}
              >
                {t("Effacer les filtres")}
              </button>
              <button
                className="text-bc-green background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleFilter}
              >
                {t("Valider")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default FilterFees;
