import { useEffect, useState } from "react";
import { useSlip } from "../../../../store/Invoice/createSlip";
import { useCreateSchedule } from "../../../../store/Invoice/schedule";
import Datepicker from "../../../Utils/dateManagement/DatePicker";
import { getCollectingBanks } from "../../../../utils/Invoice/prepayments";
import {
  bankTransferAuto,
  deleteSlipBankTransfertSchedule,
  getRibMandat,
  getSlipsSchedules,
  updateSlipBankTransfertSchedule,
} from "../../../../utils/Invoice/slips";
import { dateFormattedFr } from "../../../../utils/TimeManager/timeUtils";
import {
  successDeleteSchedule,
  updateSchedule,
} from "../../../../utils/toaster";
import { useLoadingStore } from "../../../../store/utils/loading";
import CircleLoarder from "../../../TimeManager/loader/CircleLoarder";
/**
 * @component
 * @name TableVerifySchedule
 * @description
 * Gère la verification des échéances depuis la modale de création de bordereau
 * @date 18/11/2024
 * @author Succi Iris
 */
const TableVerifySchedule = () => {
  const {
    setVerifySchedule,
    startDate,
    selectedMode,
    selectedBankSlip,
    targetDate,
    setTargetDate,
    verifySchedule,
    update,
    setUpdate,
    ribs,
    setRibs,
    selectedRib,
    setSelectedRib,
    mandats,
    setMandats,
    selectedMandat,
    setSelectedMandat,
    reset,
  } = useSlip();
  const {
    banks,
    setBanks,
    selectedBank,
    setSelectedBank,
    setSchedules,
    schedules,
  } = useCreateSchedule();
  const { isLoading, setIsLoading } = useLoadingStore();
  // Liste des échéances sélectionnées
  const [selectedSchedules, setSelectedSchedules] = useState<
    { PFIC_ID: any; FIC_CLE: any; AEC_REF: any }[]
  >([]);

  useEffect(() => {
    getCollectingBanks()
      .then((res) => {
        const formattedBanks = res.data.map((bank: any) => ({
          value: bank.ABA_REF,
          label: bank.ABA_LIBELLE,
        }));
        setBanks(formattedBanks);
        setSelectedBank(formattedBanks[0].value);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getSlipsSchedules(selectedBankSlip, startDate, selectedMode)
      .then((res) => {
        setSchedules(res.data);
      })
      .catch((err) => console.log(err));
  }, [update]);

  // Récupération des mandats et du RIB en fonction de l'échéance sélectionnée
  useEffect(() => {
    if (selectedSchedules.length > 0) {
      getRibMandat(selectedSchedules[0].PFIC_ID, selectedSchedules[0].FIC_CLE)
        .then((res) => {
          setRibs(res.data.resultRib);
        })
        .catch((err) => console.log(err));
    }
  }, [selectedSchedules]);

  // Gestion de la sélection des échéances
  const handleCheckboxChange = (schedule: {
    PFIC_ID: any;
    FIC_CLE: any;
    AEC_REF: any;
  }) => {
    setSelectedSchedules((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (item) => item.AEC_REF === schedule.AEC_REF
      );

      if (isAlreadySelected) {
        // Si déjà sélectionné, on le retire
        return prevSelected.filter((item) => item.AEC_REF !== schedule.AEC_REF);
      } else {
        // Sinon, on l'ajoute
        return [...prevSelected, schedule];
      }
    });
  };

  // Suppression des échéances sélectionnées
  const handleDeleteSchedule = () => {
    setIsLoading(true);
    const AEC_REFs = selectedSchedules.map((item) => item.AEC_REF);
    deleteSlipBankTransfertSchedule(AEC_REFs.join(","))
      .then(() => {
        setIsLoading(false);
        setSelectedSchedules([]);
        setUpdate(!update);
        successDeleteSchedule();
      })
      .catch((err) => console.log(err));
  };

  // Recherche automatique des échéances sélectionnées + mise à jour automatique
  const handleAutoSearch = () => {
    setIsLoading(true);
    const AEC_REFs = schedules.map((item: any) => item.AEC_REF);

    bankTransferAuto(AEC_REFs.join(","))
      .then(() => {
        setIsLoading(false);
        setUpdate(!update);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // Mise à jour des échéances
  const handleUpdateSchedule = () => {
    const data = {
      AEC_DATE: targetDate,
      ABA_REF: selectedBank,
      ADO_REF: selectedRib,
      AEC_RUM: selectedMandat,
    };
    updateSlipBankTransfertSchedule(selectedSchedules[0].AEC_REF, data)
      .then(() => {
        setUpdate(!update);
        updateSchedule();
        reset();
      })
      .catch((err) => console.log(err));
  };

  // Mise à jour des mandats en fonction du RIB sélectionné
  useEffect(() => {
    if (selectedRib) {
      const mandat = ribs.find((rib: any) => rib.ADO_REF === selectedRib);
      setMandats(mandat?.mandats);
    }
  }, [selectedRib]);

  return (
    verifySchedule && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[75%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t w-full">
                <h3 className="text-base md:text-3xl font-semibold text-center">
                  Gestion des échéances
                </h3>
              </div>
              {/*body*/}
              <div className="text-sm md:text-base text-center p-5 flex flex-col justify-start items-center w-11/12 m-auto">
                <div className="w-full flex justify-between items-start">
                  <div className="w-1/2 flex flex-col justify-start items-start mr-10">
                    <p>Banque de remise :</p>
                    <select
                      name="bank"
                      id="bank"
                      className="w-1/2 border-[1px] rounded border-gray-300 h-9 p-1 z-20"
                      value={selectedBank}
                      onChange={(e) => {
                        setSelectedBank(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {banks?.map((bank: any, i: any) => (
                        <option key={i} value={bank.value}>
                          {bank.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-1/2 flex flex-col justify-start items-start mr-2">
                    <p>Date de remise :</p>
                    <Datepicker
                      startDate={targetDate}
                      setStartDate={setTargetDate}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between items-start mb-2">
                  <div className="w-1/2 flex flex-col justify-start items-start mr-10">
                    <p>RIB :</p>
                    <select
                      name="rib"
                      id="rib"
                      value={selectedRib}
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                      onChange={(e) => {
                        setSelectedRib(Number(e.target.value));
                      }}
                    >
                      <option value=""></option>
                      {ribs?.map((rib: any, i: any) => (
                        <option key={i} value={rib.ADO_REF}>
                          {rib.ribText}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-1/2 flex flex-col justify-start items-start">
                    <p>Mandat :</p>
                    <select
                      name="mandat"
                      id="mandat"
                      className="w-full border-[1px] rounded border-gray-300 h-9 p-1 z-20 "
                      value={selectedMandat}
                      onChange={(e) => {
                        setSelectedMandat(e.target.value);
                      }}
                    >
                      <option value=""></option>
                      {mandats?.map((mandat: any, i: any) => (
                        <option key={i} value={mandat.MAN_RUM}>
                          {mandat.MAN_RUM}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="border-[1px] rounded border-gray-300 w-full h-52 overflow-scroll flex flex-col justify-start items-center">
                  <div className="w-full flex justify-start items-center border-b-[1px] border-gray-300 py-1 sticky bg-gray-200 top-0 ">
                    <p className="w-[2%] text-start ml-2 mr-4"></p>
                    <p className="w-[10%] text-start">Date</p>
                    <p className="w-[20%] text-start">Dossier</p>
                    <p className="w-[10%] text-start">Pièce</p>
                    <p className="w-[10%] text-start">Montant</p>
                    <p className="w-[10%] text-start">Banque</p>
                    <p className="w-[20%] text-start">RIB</p>
                    <p className="w-[10%] text-start">Mandat</p>
                  </div>
                  {isLoading ? (
                    <div className="w-full h-full flex justify-center items-center bg-bc-lightGrey">
                      <CircleLoarder />
                    </div>
                  ) : (
                    <>
                      {schedules?.map((schedule: any, i: any) => (
                        <div
                          key={i}
                          className="w-full flex justify-start items-center border-b-[1px] border-gray-300 py-1 text-sm"
                        >
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="w-[2%] ml-2 mr-4"
                            checked={selectedSchedules.some(
                              (item) => item.AEC_REF === schedule.AEC_REF
                            )}
                            onChange={() => handleCheckboxChange(schedule)}
                          />
                          <p className="w-[10%] text-start">
                            {dateFormattedFr(schedule.AEC_DATE)}
                          </p>
                          <p className="w-[20%] text-start">
                            {schedule.DOSSIER}
                          </p>
                          <p className="w-[10%] text-start">
                            {schedule.AEC_REFERENCE}
                          </p>
                          <p className="w-[10%] text-start font-bold">
                            {schedule.AEC_EMTTTC}
                          </p>
                          <p className="w-[10%] text-start">
                            {schedule.ABA_LIBELLE}
                          </p>
                          <p className="w-[20%] text-start">
                            {schedule.AEC_RIB}
                          </p>
                          <p className="w-[10%] text-start">
                            {schedule.AEC_RUM.trim()}
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setVerifySchedule(false)}
                >
                  Annuler
                </button>
                <button
                  className="bg-red-500 text-white active:bc-orange font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  onClick={handleDeleteSchedule}
                >
                  Supprimer les échéances
                </button>
                <button
                  className="bg-bc-orange text-white active:bc-orange font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  onClick={handleAutoSearch}
                >
                  Recherche auto
                </button>
                <button
                  className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    setVerifySchedule(false);
                    handleUpdateSchedule();
                  }}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default TableVerifySchedule;
