import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../utils/user";
import { logoutInterface } from "../../interface/modales";
import { useUserContext } from "../../context/UserContext";

/**
 * @component
 * @name Logout
 * @author Iris Succi
 * @date 2023
 * @description Composant pour la déconnexion
 */
const Logout: React.FC<logoutInterface> = ({ closeModal }) => {
  //Hook
  const navigate = useNavigate();
  const { alias } = useParams();

  // Context
  const { setFirstname, setId, setLastname } = useUserContext();

  const handleDeconnexion = () => {
    logout()
      .then(() => {
        localStorage.removeItem("firstRight");
        localStorage.removeItem("rightsTime");
        setFirstname("");
        setId("");
        setLastname("");
        navigate(`/login/${alias}`, { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t w-full">
              <h3 className="text-base md:text-3xl font-semibold text-center">
                Déconnexion
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-base text-center p-5">
              Etes-vous sûr de bien vouloir vous déconnecter ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="bg-bc-green text-white active:bc-green font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeconnexion();
                  closeModal();
                }}
              >
                Déconnexion
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default Logout;
