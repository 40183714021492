/* eslint-disable react-hooks/exhaustive-deps */
import { useUtilsCrmStore } from "../../../../store/Crm/utilsCrm";
import User from "../../../../assets/icons/user.svg";
import { useExplorer } from "../../../../store/Crm/Explorer";
import { useIntervenantStore } from "../../../../store/Crm/Intervenant";

/**
 * @component
 * @name InterlocuteursTree
 * @description A component that displays an interlocuteur and its details.
 * @author Iris Succi
 * @date 2023
 */
const InterlocuteursTree = ({ intervenants }: any) => {
  // Store
  const { setChoiceTab } = useUtilsCrmStore();
  const { setFicRefIntervenant } = useExplorer();
  const { setChoiceCreate } = useIntervenantStore();

  return (
    <div className="w-[97%] m-auto flex flex-col space-y-4 my-2">
      <div className="relative">
        <div className="flex items-center">
          <button
            className="flex items-center pl-4"
            onClick={() => {
              setChoiceTab("Intervenant");
              setChoiceCreate("UpdateIntervenant");
              setFicRefIntervenant(intervenants?.FIC_REF);
            }}
          >
            <img src={User} alt="user" className="w-4 h-4" />
            <div className="pl-2 font-light text-sm">
              {intervenants?.FIC_TITRE}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InterlocuteursTree;
